import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  Filter,
  SearchInput,
  useListContext,
  usePermissions,
} from "react-admin";
import { useState } from "react";

const GameAction = (props: object) => {
  const [, setTableId] = useState("");
  const { permissions } = usePermissions();

  const ListActions = () => {
    const { data } = useListContext();
    setTableId(data ? data[0].tableId : "");

    return (
      <TopToolbar>
        <SelectColumnsButton />
        {permissions.includes("admin") && <ExportButton />}
      </TopToolbar>
    );
  };

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search By table Id"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );
  return (
    <List {...props} actions={<ListActions />} filters={<UserNameFilter />}>
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="timestamp" label="Timestamp" />
        <TextField source="log" label="Log" />
      </DatagridConfigurable>
    </List>
  );
};

export default GameAction;
