import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "rsuite";

const FraudGames = () => {
  const [loading, setLoading] = useState(false);
  const [fraudGamesData, setFraudGamesData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  var date = new Date();
  date.setDate(date.getDate());
  const [joinFee, setJoinFee] = useState("");
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fraudGamesColumns = [
    {
      field: "tableId",
      headerName: "Table ID",
      flex: 1,
    },
    {
      field: "usersNoFeeDeduction",
      headerName: "User No Fee Deduction",
      flex: 1,
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const fetchFraudGames = async () => {
    try {
      setLoading(true);

      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const formatDate = (date: Date): string =>
        date.toISOString().split("T")[0];
      const startDateFetch = dateRange[0];
      const startDate = formatDate(startDateFetch);
      const endDateFetch = dateRange[1];
      const endDate = formatDate(endDateFetch);

      let dateQuery = `&startTime=${encodeURIComponent(
        startDate
      )}&endTime=${encodeURIComponent(endDate)}`;
      let userNameQuery = `&joinFee=${joinFee}`;

      const response = await axios.get(
        `${config.REST_ENDPOINT}/transactions/get-tables-noFee-deduction?key=${config.MAINTENANCE_BYPASS_KEY}${dateQuery}${userNameQuery}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const responseData = response?.data?.suspiciousTables || [];
      setFraudGamesData(responseData);
      setLoading(false);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
      setLoading(false);
    }
  };

  const getRowId = (row: any) => row.userId;

  const handleSearchClick = () => {
    setSearchClicked(true);
  };

  useEffect(() => {
    if (searchClicked) {
      fetchFraudGames();
      setSearchClicked(false);
    }
    // eslint-disable-next-line
  }, [searchClicked]);

  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginLeft: "5px", marginTop: "10px", fontWeight: "bold" }}
      >
        Fraud Games
      </Typography>

      <Grid container spacing={2} marginBottom="20px" alignItems="center">
        <Grid item xs={12} sm={3} md={3}>
          <p
            style={{
              marginLeft: "5px",
              padding: "10px 5px 5px 0px",
              float: "left",
            }}
          >
            Enter Date Range:
          </p>
          <DateRangePicker
            value={dateRange}
            onChange={handleDateSelect}
            style={{
              margin: "5px 0px 0px 0px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="Enter Join Amount"
            variant="outlined"
            fullWidth
            value={joinFee}
            onChange={(e) => setJoinFee(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            style={{ marginTop: "5px" }}
            variant="contained"
            color="primary"
            onClick={handleSearchClick}
          >
            Verify
          </Button>
        </Grid>
      </Grid>

      <Paper elevation={3} style={{ marginTop: "-2px", padding: "20px" }}>
        <DataGrid
          columns={fraudGamesColumns}
          rows={fraudGamesData}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
          getRowId={getRowId}
        />
      </Paper>
      <ReactQueryDevtools />
    </div>
  );
};

export default FraudGames;
