import { Box, CircularProgress } from "@mui/material";

const PageLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;
