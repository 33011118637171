import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {
  Filter,
  SearchInput,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Button,
  TopToolbar,
  ExportButton,
  SelectColumnsButton,
  useRefresh,
  usePermissions,
  FilterForm,
  DateTimeInput,
  FilterButton,
  useListContext,
  ShowButton,
  List,
} from "react-admin";
import { callRestApi } from "../../utils/callRestApi";
import { toast } from "react-toastify";
const Users = (props: object) => {
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  // eslint-disable-next-line
  const { total } = useListContext(); 

  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
   
  ];
 
  const handleBlock = async (id: string, blocked: boolean) => {
    try {
      if (!permissions.includes("admin")) {
        toast.error("You don't have access to this functionality");
        // return;
      }
      callRestApi(`/modify-block-status`, "PATCH", { id, blocked });
      refresh();
      toast.success(
        !blocked ? "UnBlocked User Successfully!" : "Blocked User Successfully!"
      );
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
      
    
    </Stack>
  );
  const ListActions = () => (
    <TopToolbar>
       {<TotalPagination />}
      <FilterButton filters={transactionFilters} />
     
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );
  const TotalPagination = () => {
    const { total } = useListContext();
    return <Typography variant="h6">Total: {total}</Typography>;
   
  };
    
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Mobile Number"
        source="mobileNo.number"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
        perPage={10} 
       
        
      >
        <ListToolbar />
        <Datagrid bulkActionButtons={false}>
         
          <TextField source="username" label="User Name" />
          <TextField source="totalGamePlay" label="Game Played" />
          <TextField source="wallets.mainWallet" label="Main Wallet" />
          <TextField source="wallets.winWallet" label="Win Wallet" />
          <TextField source="wallets.bonusWallet" label="Bonus Wallet" />
          <TextField source="wallets.freeWallet" label="Free Wallet "/>
          <TextField source="appVersion" label="App Version" />
          <DateField source="createdAt" showTime label="Registered At" />
          {permissions.includes("admin") && (
            <FunctionField
              label="Action"
              render={(record: any) => (
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  label={!record.isBlocked ? "Block" : "Unblock"}
                  onClick={() => handleBlock(record.id, !record.isBlocked)}
                />
              )}
            />
          )}
          <ShowButton />
        </Datagrid>
      </List>
    </>
  );
};

export default Users;