import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  Button,
  useListContext,
  usePermissions,
} from "react-admin";
import { getItemWithExpiry } from "../utils";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../config";

const IncompleteWithdrawal = (props: object) => {
  const [orderId, setOrderId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { permissions } = usePermissions();

  const onRefund = async () => {
    const user = getItemWithExpiry("user");

    if (!user.token) {
      toast.error("You have no right to refund transaction");
      return;
    }

    axios
      .patch(
        config.REST_ENDPOINT +
          "/withdrawal/refund" +
          `?key=${config.MAINTENANCE_BYPASS_KEY}`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => toast.success("Refund Successful!"))
      .catch((err: any) => toast.error(err.response.data.message));
  };

  const ListActions = () => {
    const { data } = useListContext();
    if (data) {
      setDisabled(data[0].status === data[0].cashfreeStatus);
    }
    setOrderId(data ? data[0].orderId : "");

    return (
      <TopToolbar>
        <SelectColumnsButton />
        {permissions.includes("admin") && <ExportButton />}
      </TopToolbar>
    );
  };

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
    </Filter>
  );
  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      actions={<ListActions />}
      filters={<UserNameFilter />}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="_id" label="Transaction Id" />
        <TextField source="orderId" label="Order Id" />
        <TextField source="username" label="User Name" />
        <TextField source="amount" label="Amount" />
        <TextField source="paymentMode" label="Payment Mode" />
        <TextField source="paymentGateway" label="Payment Gateway" />
        <TextField source="txnType" label="Payment Details" />
        <ChipField source="status" label="Status" />
        <DateField source="createdAt" showTime label="Requested At" />
        <DateField source="updatedAt" showTime label="Updated At" />
        <ChipField source="cashfreeStatus" label="Cashfree Status" />
        <Button
          onClick={onRefund}
          label="Refund"
          disabled={disabled}
          variant="contained"
          sx={{ textTransform: "none" }}
        />
      </DatagridConfigurable>
    </List>
  );
};

export default IncompleteWithdrawal;
