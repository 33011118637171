import {
  List,
  TextField,
  DateField,
  DatagridConfigurable,
  Filter,
  SearchInput,
  FunctionField,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
const GameOnline = () => {
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search TableId"
        source="tableId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search JoinFee"
        source="joinFee"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder=" Search UserId "
        source="userId"
        resettable
        alwaysOn
      />
     
    </Filter>
  );
  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <List
        resource="game-online"
        sort={{ field: "Created at", order: "DESC" }}
        filters={<UserNameFilter />}
        filter={{ status: "started" }}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="tableId" label="Game Table / Room Code" />
         <TextField source="joinFee" label="Join Fee" />
          <TextField source="winAmount" label="Win Amount" />
          <TextField source="status" label="Status" />
          <DateField source="createdAt" showTime label="Game started At" />
          <FunctionField
            label="User ID"
            render={(record: any) =>
              record.players.map((player: any) => player.userId).join(", ")
            }
          />
        <FunctionField
        label="Room Size"
         render={(record: any) => {
         const numberOfPlayers = record.players.length;
         return numberOfPlayers;
  }}
/>
 </DatagridConfigurable>
      </List>
    </>
  );
};
export default GameOnline;
