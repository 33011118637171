import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
  SelectArrayInput,
  FilterForm,
  usePermissions,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  ExportButton,
  DateTimeInput,
} from "react-admin";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";
import { Stack } from "@mui/material";

const transactionFilters = [
  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
  <SelectArrayInput
    label="Filter by type"
    source="type"
    choices={[
      { id: "joinFee", name: "JoinFee" },
      { id: "winning", name: "Winning" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const Gametransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search RoomCode"
        source="tableId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search txnAmount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="type" label="Type" />
          <ChipField source="tableId" label="Room Code" />
          <TextField source="amount" label="txnAmount" />
          <TextField source="mainwallet" label="Main Wallet" />
          <TextField source="winwallet" label="Win Wallet" />
          <DateField source="createdAt" showTime label="Created At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Gametransactions;
