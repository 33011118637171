import { reduce, reverse, split } from "lodash";

export function formatCurrencyWithCommas(number: number) {
  let numberStr = String(number);

  // Reverse the string and split it into an array of characters
  let characters = reverse(split(numberStr, ""));

  // Insert commas after every 2 characters, except for the first character
  let formattedStr = reduce(
    characters,
    (result, character, index) => {
      if (index !== 0 && index % 2 === 0) {
        // Check if the previous two characters are both digits
        if (isDigit(character) && isDigit(characters[index - 1])) {
          if (index % 6 === 0) {
            return character + "," + result;
          }
          return character + result;
        } else {
          return character + "," + result;
        }
      }
      return character + result;
    },
    ""
  );

  return formattedStr;
}

function isDigit(character: string) {
  return /^\d$/.test(character);
}
