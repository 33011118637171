import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import StatsCard from "../components/StatsCard";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import { DateRangePicker } from "rsuite";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

const TableReports = () => {
  const [loading, setLoading] = useState(false);
  const [classicStats, setClassicStats] = useState<
    { joinfee: number; totalCount: any }[]
  >([]);
  const [quickStats, setQuickStats] = useState<
    { joinfee: number; totalCount: any }[]
  >([]);
  const [furious4Stats, setFurious4Stats] = useState<
    { joinfee: number; totalCount: any }[]
  >([]);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [dateRangeKey, setDateRangeKey] = useState(0);
  const fetchData = async (joinfee: number, gameType: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
  
      if (dateRange[0] !== null && dateRange[1] !== null) {
        const startDateFetch = dateRange[0] as Date;
        startDateFetch.setHours(0, 0, 0, 0);
        const startDate = new Date(
          startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
        ).toJSON();
        const endDateFetch = dateRange[1] as Date;
        endDateFetch.setHours(23, 59, 59, 999);
        const endDate = new Date(
          endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
        ).toJSON();
        const filter = {
          startDate,
          endDate,
        };
  
        const response = await axios.get(
          `${config.REST_ENDPOINT}/ludo/joinfee/count?joinfee=${joinfee}&gameType=${gameType}&filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
  
        if (response && response.data && response.data.getTotalCount) {
          const totalCount =
            response.data.getTotalCount[0]?.totalCount || 0;
          return { joinfee, totalCount };
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchClassicData = async () => {
    setClassicStats([]);
    const classicJoinFees = [
      1, 5, 10, 30, 100, 300, 500, 1000, 2000, 3000, 5000, 10000,
    ];
    for (const joinfee of classicJoinFees) {
      const stat = await fetchData(joinfee, "classic");
      if (stat) {
        setClassicStats((prevStats) => [...prevStats, stat]);
      }
    }
  };
  
  const fetchQuickData = async () => {
    setQuickStats([]);
    const quickJoinFees = [
      1, 5, 10, 30, 100, 300, 500, 1000, 2000, 3000, 5000, 10000,
    ];
    for (const joinfee of quickJoinFees) {
      const stat = await fetchData(joinfee, "quick");
      if (stat) {
        setQuickStats((prevStats) => [...prevStats, stat]);
      }
    }
  };
  
  const fetchFurious4Data = async () => {
    setFurious4Stats([]);
    const furious4JoinFees = [
      1, 5, 10, 30, 100, 300, 500, 1000, 2000, 3000, 5000, 10000,
    ];
    for (const joinfee of furious4JoinFees) {
      const stat = await fetchData(joinfee, "furious4");
      if (stat) {
        setFurious4Stats((prevStats) => [...prevStats, stat]);
      }
    }
  };
  
  const fetchAllData = async () => {
    setLoading(true);
    await fetchClassicData();
    await fetchQuickData();
    await fetchFurious4Data();
    setLoading(false);
  };
  
  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line
  }, [dateRangeKey]);
  


  const handleDateSelect = (value: any) => {
    setDateRange(value);
    setDateRangeKey((prevKey) => prevKey + 1);
  };


  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
<div>
    
        <Grid item xs={12} sm={3}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <p style={{ marginRight: "10px" }}>
              <strong>Select Date Range:</strong>
            </p>
            <DateRangePicker value={dateRange} onChange={handleDateSelect} />
            
          </div>
          </Grid>



      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold" style={{ textAlign: "center" }}>
                Classic Table count
              </Typography>
            }
            value={classicStats.reduce((sum, stat) => sum + stat.totalCount, 0)}
            icon={
              <CloudDoneIcon style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Classic Join Fee</TableCell>
                  <TableCell>No. of Games</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classicStats.map((stat) => (
                  <TableRow key={stat.joinfee}>
                    <TableCell>{stat.joinfee}</TableCell>
                    <TableCell>{stat.totalCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Quick Table count</Typography>}
            value={quickStats.reduce((sum, stat) => sum + stat.totalCount, 0)}
            icon={
              <CloudDoneIcon style={{ fontSize: "20px", color: "#af504c" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Quick Join Fee</TableCell>
                  <TableCell>No. of Games</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quickStats.map((stat) => (
                  <TableRow key={stat.joinfee}>
                    <TableCell>{stat.joinfee}</TableCell>
                    <TableCell>{stat.totalCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Furious4 Table count</Typography>
            }
            value={furious4Stats.reduce(
              (sum, stat) => sum + stat.totalCount,
              0
            )}
            icon={<CloudDoneIcon style={{ fontSize: "20px" }} />}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Furious4 Join Fee</TableCell>
                  <TableCell>No. of Games</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {furious4Stats.map((stat) => (
                  <TableRow key={stat.joinfee}>
                    <TableCell>{stat.joinfee}</TableCell>
                    <TableCell>{stat.totalCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default TableReports;