import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Paper,
  CircularProgress,
  Button,
} from "@mui/material";
import { Confirm, useNotify, usePermissions } from "react-admin";
import { GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { get, isEmpty, toUpper } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { callRestApi } from "../../utils/callRestApi";

import UserInfoRow from "../../components/UserInfoRow";
import SimpleTable from "../../components/SimpleTable";
import StatusField from "../../components/StatusField";
import WalletTransactionModal from "../../components/WalletTransactionModal";
import ManageRoleModal from "./ManageRoleModal";
const Details = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAddMoney, setOpenAddMoney] = useState(false);
  const [orderId] = useState();
  const [tableId] = useState();
  const [openDeductMoney, setOpenDeductMoney] = useState(false);
  const [userData, setUserData] = useState();
  const [openManageRoles, setOpenManageRoles] = useState(false);
  const navigate = useNavigate();
  const handleLinkClick = (path: any) => {
    navigate(path);
    window.location.reload();
  };

  useEffect(() => {
    fetchUserDetails(id);

    // Cleanup function
    return () => {};
    // eslint-disable-next-line
  }, []);

  const { id } = useParams();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const fetchUserDetails = async (userId: any) => {
    setLoading(true);
    try {
      const userRes = await callRestApi(`/users/${userId}`, "GET", {});
      setUserData(get(userRes, "data"));
      // eslint-disable-next-line
      const refundHistoryRes = await callRestApi(
        `/records/admin/refund`,
        "GET",
        { userId: id }
      );
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const gameRecordsColumns = [
    {
      field: "roomCode",
      headerName: "Room Code",
      flex: 1,
    },
    {
      field: "roomSize",
      headerName: "Room Size",
      type: "number",
      flex: 0.5,
    },
    {
      field: "gameType",
      headerName: "Game Type",
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.gameType,
    },
    {
      field: "joinAmount",
      headerName: "Join Amount",
      type: "number",
      flex: 0.5,
    },
    {
      field: "winOrLoseAmount",
      headerName: "Profit/Loss (RS)",
      type: "number",
      flex: 0.5,
    },
    {
      field: "outcome",
      headerName: "Outcome",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Date Time",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];

  const withdrawalsColumns = [
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  const depositColumns = [
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];
  const bonusTransactionColumns = [
    { field: "amount", headerName: "Amount", flex: 0.7 },
    {
      field: "type",
      headerName: "Type",
      flex: 0.7,
      valueGetter: (params: any) => {
        const type = params.row.type;
        return type === "bonus" ? "Joining" : type === "coupon" ? "Coupon" : "";
      },
    },
    {
      field: "transactionByUserName",
      headerName: "Transaction By",
      flex: 1,
      renderCell: (params: GridValueGetterParams) =>
        !isEmpty(params?.row?.transactionByUserName) ? (
          <Link to={`/users/${params.row.transactionBy}/show`}>
            {params.row.transactionByUserName}
          </Link>
        ) : (
          <>{params.row.transactionBy}</>
        ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];
  const referredUsersColumns = [
    { field: "referredUserName", headerName: "User Name", flex: 1 },
    { field: "totalMatches", headerName: "Total Matches", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];

  const tournamentsUsersColumns = [
    {
      field: "tournamentId",
      headerName: "Tournament ID",
      flex: 0.7,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/all-tournaments/${row.tournamentId}/show`}
          onClick={() =>
            handleLinkClick(`/all-tournaments/${row.tournamentId}/show`)
          }
        >
          {row.tournamentId}
        </Link>
      ),
    },
    { field: "tournamentName", headerName: "Tournament Name", flex: 0.8 },
    {
      field: "type",
      headerName: "Type",
      flex: 0.4,
      valueGetter: (params: any) => {
        const type = params.row.type;
        return type === "tournament"
          ? "Join"
          : type === "tournamentPrize"
          ? "Win"
          : "";
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.4,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];
  const refundUsersColumns = [
    { field: "amount", headerName: "Amount", flex: 1 },
    {
      field: "createdAt",
      headerName: " Date & Time",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const row = params.row;
        return `${new Date(row.createdAt).toLocaleString()}`;
      },
    },

    {
      field: "gameMode ",
      headerName: "Game Mode",
      flex: 1,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <>
          {row.type === "refund" && <span>Classic / Quick Mode</span>}
          {row.type === "tournamentRefund" && <span>Tournament Refund</span>}
          {row.type === "tdsRefund" && <span>Tds Refund</span>}
        </>
      ),
    },

    {
      field: "tournamentId",
      headerName: "Tournament ID",
      flex: 0.7,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/all-tournaments/${row.tournamentId}/show`}
          onClick={() =>
            handleLinkClick(`/all-tournaments/${row.tournamentId}/show`)
          }
        >
          {row.tournamentId}
        </Link>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleBlock = (id: string, isBlocked: boolean) => {
    try {
      callRestApi(`/block`, "PATCH", { id });
      notify(
        !isBlocked
          ? "Blocked User Successfully!"
          : "Unblocked User Successfully!",
        {
          multiLine: true,
          type: "success",
        }
      );
    } catch (e) {
      notify("Something went wrong!", {
        multiLine: true,
        type: "error",
      });
    }
    setIsConfirmModalOpen(false);
  };

  if (!isEmpty(userData)) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <h3 style={{ marginLeft: "24px" }}>User Details</h3>
            <Item>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Avatar
                    alt="Profile Pic"
                    src={get(
                      userData,
                      "profilePic",
                      "https://ludoempire-pub.s3.ap-south-1.amazonaws.com/default-profile-pic.png"
                    )}
                    sx={{
                      width: 100,
                      height: 100,
                      border: "3px solid #d2d6de",
                    }}
                  />
                </Box>
                <Box>
                  <UserInfoRow
                    label="User Name"
                    value={get(userData, "username", "")}
                  />
                  <UserInfoRow
                    label="Numeric Id"
                    value={get(userData, "numericId", "")}
                  />
                </Box>
                <Divider light />
                <Box marginTop={2}>
                  <UserInfoRow
                    label="Full Name"
                    value={get(userData, "name", "")}
                  />
                  <UserInfoRow
                    label="Mobile Number"
                    value={`${get(userData, "mobileNo.countryCode")}-${get(
                      userData,
                      "mobileNo.number"
                    )}`}
                  />
                  {get(userData, "email", "") && (
                    <UserInfoRow
                      label="E-Mail Id"
                      value={`${get(userData, "email", "")}`}
                    />
                  )}
                  {get(userData, "email", "") && (
                    <UserInfoRow
                      label="E-Mail Verified"
                      value={
                        <StatusField
                          value={get(userData, "isEmailVerified", false)}
                          booleanLabel={
                            get(userData, "isEmailVerified", false)
                              ? "Verified"
                              : "Not Verified"
                          }
                          source="boolean"
                        />
                      }
                    />
                  )}
                  <UserInfoRow
                    label="Main Wallet"
                    value={`${get(userData, "wallet.mainWallet", 0)}`}
                  />
                  <UserInfoRow
                    label="Win Wallet"
                    value={`${get(userData, "wallet.winWallet", 0)}`}
                  />
                  <UserInfoRow
                    label="Bonus Wallet"
                    value={`${get(userData, "wallet.bonusWallet", 0)}`}
                  />
                  <UserInfoRow
                    label="Free Wallet"
                    value={`${get(userData, "wallet.freeWallet", 0)}`}
                  />
                  <UserInfoRow
                    label="Referred Users"
                    value={`${get(userData, "referrals", []).length}`}
                  />
                  <UserInfoRow
                    label="KYC Status"
                    value={
                      <StatusField
                        value={toUpper(get(userData, "userKycStatus", "-"))}
                        source="kyc"
                      />
                    }
                  />
                </Box>
                {permissions.includes("admin") && (
                  <Box marginTop={2} display="flex" flexDirection="column">
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenAddMoney(true)}
                    >
                      Add Money
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenDeductMoney(true)}
                    >
                      Deduct Money
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenManageRoles(true)}
                    >
                      Manage Roles
                    </Button>
                  </Box>
                )}
              </Box>
            </Item>
            <h3 style={{ marginLeft: "24px" }}>Device Information</h3>
            <Item>
              <Box>
                <UserInfoRow
                  label="Device Model"
                  value={get(userData, "devices.0.model", "-")}
                />
                <UserInfoRow
                  label="Operating System"
                  value={get(userData, "devices.0.os", "-")}
                />
                <UserInfoRow
                  label="RAM (MB)"
                  value={`${get(userData, "devices.0.ram", "-")}`}
                />
                <UserInfoRow
                  label="Processor"
                  value={get(userData, "devices.0.processor", "-")}
                />
                <UserInfoRow
                  label="Game Version"
                  value={get(userData, "appVersion", "-")}
                />
              </Box>
            </Item>
          </Grid>
          <Grid item xs={9}>
            <h3 style={{ marginLeft: "24px" }}>Game Played</h3>
            <Item>
              <SimpleTable
                columns={gameRecordsColumns}
                data={get(userData, "gameHistory", [])}
                filter={{ userId: id }}
                endPointData={{
                  apiEndpoint: "/game-records",
                }}
                dataMode="server"
              />
            </Item>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "24px" }}>Withdrawals</h3>
                <Item>
                  <SimpleTable
                    columns={withdrawalsColumns}
                    data={get(userData, "withdrawalHistory", [])}
                    filter={{
                      userId: id,
                      txnType: "debit",
                    }}
                    endPointData={{
                      apiEndpoint: "/transactions",
                    }}
                    dataMode="server"
                  />
                  <UserInfoRow
                    label="Total Withdrawals"
                    value={`${get(
                      userData,
                      "paymentStatistics[0].totalSuccessWithdrawals",
                      0
                    )}`}
                  />
                  <UserInfoRow
                    label="Total Withdrawal Amount"
                    value={`₹${get(
                      userData,
                      "paymentStatistics[0].totalSuccessWithdrawalsAmount",
                      0
                    )}`}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "24px" }}>Deposits</h3>
                <Item>
                  <SimpleTable
                    columns={depositColumns}
                    data={get(userData, "depositHistory", [])}
                    filter={{
                      userId: id,
                      txnType: "credit",
                    }}
                    endPointData={{
                      apiEndpoint: "/transactions",
                    }}
                    dataMode="server"
                  />
                  <UserInfoRow
                    label="Total Deposits"
                    value={`${get(
                      userData,
                      "paymentStatistics[0].totalSuccessDeposits",
                      0
                    )}`}
                  />
                  <UserInfoRow
                    label="Total Deposit Amount"
                    value={`₹${get(
                      userData,
                      "paymentStatistics[0].totalSuccessDepositsAmount",
                      0
                    )}`}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>
                  Bonus Wallet Transactions
                </h4>
                <Item>
                  <SimpleTable
                    columns={bonusTransactionColumns}
                    data={get(userData, "depositHistory", [])}
                    filter={{
                      userId: id,
                      type: ["bonus", "coupon"],
                    }}
                    endPointData={{
                      apiEndpoint: "/wallets/transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>Referred Users</h4>
                <Item>
                  <SimpleTable
                    columns={referredUsersColumns}
                    data={[]}
                    filter={{
                      userId: id,
                    }}
                    endPointData={{
                      apiEndpoint: "/referrals",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>Tournament gameplay</h4>
                <Item>
                  <SimpleTable
                    columns={tournamentsUsersColumns}
                    data={[]}
                    filter={{
                      userId: id,
                      type: ["tournament", "tournamentPrize"],
                    }}
                    endPointData={{
                      apiEndpoint: "/wallets/transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}> Refund</h4>
                <Item>
                  <SimpleTable
                    columns={refundUsersColumns}
                    data={[]}
                    filter={{
                      userId: id,
                    }}
                    endPointData={{
                      apiEndpoint: "/records/admin/refund",
                    }}
                    dataMode="server"
                    rowId="createdAt"
                  />
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isConfirmModalOpen && (
          <Confirm
            isOpen={isConfirmModalOpen}
            title={`Please Confirm the Action`}
            content="Are you sure?"
            onConfirm={() =>
              handleBlock(
                get(userData, "id", ""),
                get(userData, "isBlocked", true)
              )
            }
            onClose={() => setIsConfirmModalOpen(false)}
            loading={loading}
          />
        )}
        {openAddMoney && (
          <WalletTransactionModal
            open={openAddMoney}
            close={() => setOpenAddMoney(false)}
            title="Add Money to Wallet"
            userId={id}
            orderId={orderId}
            tableId={tableId}
            trasnaction="credit"
            notify={notify}
          />
        )}
        {openDeductMoney && (
          <WalletTransactionModal
            open={openDeductMoney}
            close={() => setOpenDeductMoney(false)}
            title="Deduct Money from Wallet"
            userId={id}
            trasnaction="debit"
            notify={notify}
          />
        )}

        {openManageRoles && (
          <ManageRoleModal
            open={openManageRoles}
            close={() => setOpenManageRoles(false)}
            userId={id}
            userRoles={get(userData, "roles", "")}
          />
        )}
      </div>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
};

export default Details;
