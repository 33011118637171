import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { callRestApi } from "../utils/callRestApi";

const WalletTransactionModal = (props:any) => {
  const { open, close, userId, title, trasnaction, notify } = props;
  const [formData, setFormData] = useState({
    amount: "",
    selectedField: "amount",
    orderId: "",
    tableId: "",
    wallet: "",
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setMessage("");
    if (!formData.amount || !formData.wallet) {
      setMessage("Amount and Wallet are mandatory fields.");
      return;
    }

    if (formData.selectedField === "orderId" && !formData.orderId) {
      setMessage("Order ID is mandatory when selected.");
      return;
    }
    if (formData.selectedField === "tableId" && !formData.tableId) {
      setMessage("Table ID is mandatory when selected.");
      return;
    }

    try {
      setLoading(true);
      const response = await callRestApi(
        trasnaction === "credit"
          ? "/add-money-to-wallet"
          : "/deduct-money-from-wallet",
        "POST",
        { id: userId, ...formData }
      );

      if (response?.data?.statusCode === 201) {
        notify(response?.data?.message || "Wallet Updated. Please Check", {
          multiLine: true,
          type: "success",
        });
        setLoading(false);
        close();
      } else {
        notify(response?.data?.message || "Something Wrong", {
          multiLine: true,
          type: "error",
        });
        setLoading(false);
      }
    } catch (error) {

      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
      <Box>
          {!message ? null : (
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          )}
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <FormControl fullWidth>
            <TextField
              id="amount"
              type="text"
              label="Amount"
              variant="outlined"
              fullWidth
              required
              value={formData.amount}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  amount: event.target.value,
                }));
              }}
            />
                      <RadioGroup
              row
              aria-label="Field"
              name="selectedField"
              value={formData.selectedField}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  selectedField: event.target.value,
                }));
              }}
            >

              <FormControlLabel
                value="orderId"
                control={<Radio />}
                label="Order ID"
              />
              <FormControlLabel
                value="tableId"
                control={<Radio />}
                label="Table ID"
              />
            </RadioGroup>
            {formData.selectedField === "orderId" ? (
              <TextField
                id="orderId"
                label="Order ID"
                fullWidth
                variant="outlined"
                required
                value={formData.orderId}
                onChange={(event) => {
                  setFormData((prev) => ({
                    ...prev,
                    orderId: event.target.value,
                  }));
                }}
              />
            ) : (
              <TextField
                id="tableId"
                label="Table ID"
                fullWidth
                variant="outlined"
                required
                value={formData.tableId}
                onChange={(event) => {
                  setFormData((prev) => ({
                    ...prev,
                    tableId: event.target.value,
                  }));
                }}
                />
            )}
          </FormControl>
          <FormControl>
            <FormLabel id="walletLabel">Wallet</FormLabel>
            <RadioGroup
              row
              aria-labelledby="walletLabel"
              name="wallet"
              value={formData.wallet}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  wallet: event.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="mainWallet"
                control={<Radio />}
                label="Main Wallet"
              />
              <FormControlLabel
                value="winningWallet"
                control={<Radio />}
                label="Winning Wallet"
              />
              <FormControlLabel
                value="bonusWallet"
                control={<Radio />}
                label="Bonus Wallet"
              />
              <FormControlLabel
              value="freeWallet"
              control={<Radio />}
              label= "Free Wallet"
              />
            </RadioGroup>
          </FormControl>
          </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="info" variant="contained">
          Close
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onSave}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletTransactionModal;
