import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography, Box } from "@mui/material";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import StatsCard from "../components/StatsCard";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";
const UserCount = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    userCount: 0,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      const response = await axios.get(
        `${config.REST_ENDPOINT}/ludo/online/users`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response && response.data) {
        const { data } = response;
        setStats(data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Total User</Typography>}
            value={stats.userCount}
            icon={
              <CloudDoneIcon style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserCount;
