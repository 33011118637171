import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";

const Withdrawals = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
      ]}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="currency" />
          <TextField source="amount" />
          <TextField source="paymentMode" />
          <TextField source="paymentGateway" />
          <TextField source="payoutType" />
          <TextField source="txnMode" />
          <TextField source="txnType" />
          <ChipField source="status" />
          <DateField
            source="createdAt"
            showTime
            options={{ timeZone: "UTC" }}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Withdrawals;
