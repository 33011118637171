import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import StatsCard from "../components/StatsCard";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import { DateRangePicker } from "rsuite";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

const TableReports = () => {
  const [loading, setLoading] = useState(false);
  const [tournamentStats, setTournamentStats] = useState<
    { joinfee: number; totalCount: any }[]
  >([]);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [dateRangeKey, setDateRangeKey] = useState(0);
  const fetchData = async (joinfee: number) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      if (dateRange[0] !== null && dateRange[1] !== null) {
        const startDateFetch = dateRange[0] as Date;
        startDateFetch.setHours(0, 0, 0, 0);
        const startDate = new Date(
          startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
        ).toJSON();
        const endDateFetch = dateRange[1] as Date;
        endDateFetch.setHours(23, 59, 59, 999);
        const endDate = new Date(
          endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
        ).toJSON();
        const filter = {
          startDate,
          endDate,
        };
        const response = await axios.get(
          `${config.REST_ENDPOINT}/tournaments/joinfee/count?joinfee=${joinfee}&filter=${encodeURIComponent(
            JSON.stringify(filter))
          }`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        

      if (response && response.data && response.data.getTotalCount) {
        const totalCount = response.data.getTotalCount[0]?.totalCount || 0;
        return { joinfee, totalCount };
      }
    }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTournamentData = async () => {
    setTournamentStats([]);
    const tournamentJoinFees = [1, 5, 10, 30, 100, 300, 500];
    for (const joinfee of tournamentJoinFees) {
      const stat = await fetchData(joinfee);
      if (stat) {
        setTournamentStats((prevStats) => [...prevStats, stat]);
      }
    }
  };
  const fetchAllData = async () => {
    setLoading(true);
    await fetchTournamentData();
    setLoading(false);
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line
  }, [dateRangeKey]);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
    setDateRangeKey((prevKey) => prevKey + 1);
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>

<Grid item xs={12} sm={3}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <p style={{ marginRight: "10px" }}>
              <strong>Select Date Range:</strong>
            </p>
            <DateRangePicker value={dateRange} onChange={handleDateSelect} />
            
          </div>
          </Grid>
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold" style={{ textAlign: "center" }}>
                Total Tournament count
              </Typography>
            }
            value={tournamentStats.reduce(
              (sum, stat) => sum + stat.totalCount,
              0
            )}
            icon={
              <CloudDoneIcon style={{ fontSize: "20px", color: "#FFDB58" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Tournament Join Fee
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    No. of Tournaments
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tournamentStats.map((stat) => (
                  <TableRow key={stat.joinfee}>
                    <TableCell>{stat.joinfee} </TableCell>
                    <TableCell>{stat.totalCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default TableReports;
