import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";
import { get, isEmpty } from "lodash";

const ProcessedRequests = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
    <SelectArrayInput
      label="Filter by Payout Type"
      source="payoutType"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "IMPS", name: "IMPS" },
      ]}
    />,
   
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search By UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Transaction Id"
        source="_id"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="_id" label="Transaction Id" />
          <TextField source="orderId" label="Order Id" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="amount" />
          <TextField source="paymentGateway" />
          <TextField source="payoutType" />
          <ChipField source="payoutStatus" />
          <FunctionField
            label="Account Details"
            render={(record: any) => {
              return !isEmpty(get(record, "vpa")) ? (
                <TextField source="vpa" />
              ) : (
                <>
                  <TextField source="account.accountNo" />
                  <br />
                  <TextField source="account.ifscCode" />
                </>
              );
            }}
          />
          <DateField source="createdAt" showTime label="Requested At" />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default ProcessedRequests;
