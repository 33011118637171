import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";

const TournamentTransaction = () => {

  const TournamentNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search TournamentName"
        source="tournamenName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search TournamentId"
        source="tournamentId"
        resettable
        alwaysOn
      />
      
    </Filter>
  );

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <List
        resource="tournaments/transaction/history"
        sort={{ field: "Created at", order: "DESC" }}
        filters={<TournamentNameFilter />}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="tournamentId" label="Tournament ID"/>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="tournamenName" label="Tournament Name"/>
          <ChipField source="joinFee" label="Join Fee" />
          <TextField source="winAmount" label="Win Amount"/>
          <DateField source="createdAt" showTime label="Created At"/>
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default TournamentTransaction;
