import { useEffect, useState } from 'react';
import {
  TopToolbar,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  BooleanField,
  FilterForm,
  DateTimeInput,
  FilterButton,
} from 'react-admin';
import UserNameHyperlink from '../components/react-admin/UserNameHyperlink';
import { CSVLink } from 'react-csv';
import axios from 'axios'; 
import config from '../config';
import { getItemWithExpiry } from '../utils/sessionData';
import { Stack } from 'rsuite';

const TaxDeductions = (props:any) => {
  const { permissions } = usePermissions();
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const user = getItemWithExpiry("user");
    if (!user.token) {
   return;
    }
  
    axios.get(config.REST_ENDPOINT + "/tax-deductions", 
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
    )
      .then((response) => {
        console.log('response is', response)
        setApiData(response.data.items); 
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });
  }, []); 
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );
  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];
  const ListActions = () => (
    <TopToolbar>
     <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes('admin')}
      <CSVLink
        data={apiData}
        headers={getHeaders(apiData[0])}
        separator=','
        enclosingCharacter='"'
        filename={'tax_deductions.csv'}
      >
        Export to CSV
      </CSVLink>
    </TopToolbar>
  );

  const UserNameFilter = (props:any) => (
    <Filter {...props}>
      <SearchInput placeholder="Search UserName" source="userName" alwaysOn />
      <SearchInput
        placeholder="Search PanCard"
        source="panCard"
        resettable
        alwaysOn
      />
    
    </Filter>
  );

  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<ListActions />}
      filters={<UserNameFilter />}
    >
         <ListToolbar/>
      <DatagridConfigurable bulkActionButtons={false}>
        <UserNameHyperlink label="User Name" source="userName" to="userId" />
        <TextField source="panCard" />
        <TextField source="fullName" />
        <TextField source="financialYear" />
        <TextField source="totalDepositAmount" />
        <TextField source="totalWithdrawalAmount" />
        <TextField source="amountRequestedToWithdrawal" />
        <TextField source="withdrawalAmountAfterTaxDeduction" />
        <TextField source="totalTdsAmountDeducted" />
        <TextField source="netWithdrawalAmount" />
        <BooleanField source="isTdsDeducted" />
        <BooleanField source="isWithdrawalSuccess" />
        <DateField source="transactionFrom" showTime />
        <DateField source="transactionTo" showTime />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </DatagridConfigurable>
    </List>
  );
};

const getHeaders = (item:any) => {
  if (item) {
    return Object.keys(item);
  }
  return [];
};

export default TaxDeductions;