import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const KycZoopImageModal = (props: any) => {
  const { open, close, data } = props;
  const { kyc, userName } = data;

  const DisplayAadhar = (props: any) => {
    const { aadharFrontUrl, aadharBackUrl, aadharCardNumber } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "auto",
          width: "fit-content",
        }}
      >
        {kyc.cardType === "AADHAAR" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 style={{ marginBottom: "10px" }}>Aadhar Card Front Image</h2>
            <img
              src={aadharFrontUrl}
              alt="Aadhar Card Front"
              width="50%"
              loading="lazy"
            />
          </Box>
        )}
        {kyc.cardType === "AADHAAR" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 style={{ marginBottom: "10px" }}>Aadhar Card Back Image</h2>
            <img
              src={aadharBackUrl}
              alt="Aadhar Card Back"
              width="50%"
              loading="lazy"
            />
          </Box>
        )}
        {kyc.AadharNumber && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 style={{ marginBottom: "10px" }}>Aadhar Card Number</h2>
            <p style={{ fontSize: "1.5em" }}>{aadharCardNumber}</p>
          </Box>
        )}
      </Box>
    );
  };

  const DisplayVoterID = (props: any) => {
    const { voterFrontUrl, voterBackUrl } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "auto",
          width: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginBottom: "10px" }}>Voter ID Card Front Image</h2>
          <img
            src={voterFrontUrl}
            alt="Voter ID Card Front"
            width="50%"
            loading="lazy"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginBottom: "10px" }}>Voter ID Card Back Image</h2>
          <img
            src={voterBackUrl}
            alt="Voter ID Card Back"
            width="50%"
            loading="lazy"
          />
        </Box>
      </Box>
    );
  };

  const DisplayPanCard = (props: any) => {
    const { panUrl } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 style={{ marginBottom: "10px" }}>Pan Card Image</h2>
        <img src={panUrl} alt="Pan Card" width="50%" loading="lazy" />
      </Box>
    );
  };

  const DisplayDL = (props: any) => {
    const { dlUrl } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 style={{ marginBottom: "10px" }}>Driving License Image</h2>
        <img src={dlUrl} alt="Driving License" width="50%" loading="lazy" />
      </Box>
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
      <DialogTitle>KYC Documents of {userName}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
          }}
        >
          <DisplayAadhar
            aadharFrontUrl={kyc.frontImageUrl}
            aadharBackUrl={kyc.backImageUrl}
            aadharCardNumber={kyc.AadharNumber}
          />
          {kyc.cardType === "PAN" && (
            <DisplayPanCard panUrl={kyc.frontImageUrl} />
          )}
          {kyc.cardType === "VOTERID" && (
            <DisplayVoterID
              voterFrontUrl={kyc.frontImageUrl}
              voterBackUrl={kyc.backImageUrl}
            />
          )}
          {kyc.cardType === "DRIVING_LICENSE" && (
            <DisplayDL dlUrl={kyc.frontImageUrl} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KycZoopImageModal;
