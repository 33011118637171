import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const KycImageModal = (props: any) => {
  const { open, close, data } = props;
  const { imageToDisplay, kyc, userName } = data;

  const DisplayAadhar = (props: any) => {
    const { aadharFrontUrl, aadharBackUrl } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "auto",
          width: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginBottom: "10px" }}>
            Aadhar Card Front Image
          </h2>
          <img src={aadharFrontUrl} alt="Aadhar Card Front" width="50%" loading="lazy" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginBottom: "10px" }}>
            Aadhar Card Back Image
          </h2>
          <img src={aadharBackUrl} alt="Aadhar Card Back" width="50%" loading="lazy" />
        </Box>
      </Box>
    );
  };

  const DisplayPanCard = (props: any) => {
    const { panUrl } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 style={{ marginBottom: "10px" }}>Pan Card Image</h2>
        <img src={panUrl} alt="Pan Card" width="50%" loading="lazy" />
      </Box>
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
      <DialogTitle>KYC Documents of {userName}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
          }}
        >
          {imageToDisplay === "aadharCard" && (
            <DisplayAadhar
              aadharFrontUrl={kyc.aadharFrontUrl}
              aadharBackUrl={kyc.aadharBackUrl}
              aadharNumber={kyc.aadharNumber}
            />
          )}
          {imageToDisplay === "panCard" && (
            <DisplayPanCard panUrl={kyc.panUrl} panNumber={kyc.panNumber} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KycImageModal;
