import { useState, useEffect} from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import Big from "big.js";
import {
  AccountCircleRounded,
  Home,
  Handshake,
} from "@mui/icons-material";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { toast } from "react-toastify";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { getItemWithExpiry } from "../utils";
import axios from "axios";
import config from "../config";
import PaletteIcon from "@mui/icons-material/Palette";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

import { DateRangePicker } from "rsuite";
import { Box, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
  },
  card: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: "35px",
    marginRight: theme.spacing(2),
  },
  subtitle1: {
    fontSize: "20px",
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  clenderWrap: {
    display: "flex",
    justifyContent: "space-evenly",
    fontSize: "20px",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {});

  const [stats, setStats] = useState({
    totalUsers: 0,
    totalWalletBonuses: 0,
    totalReferrals: 0,
    totalReferralsBonus: 0,
    depositCounts: 0,
    depositAmount: 0,
    withdrawCounts: 0,
    withdrawlTotalAmount: 0,
    totalWalletBonusCount: 0,
    totalRoomJoinFees: 0,
    totalWinAmount: 0,
    totalComission: 0,
    totalWalletBonus: 0, // This refers to total Signup Bonus
    quickGamesTotal: 0,
    quickGamesAmount: 0,
    classicGamesTotal: 0,
    classicGamesAmount: 0,
    tournamentGamesTotal: 0,
    tournamentAmount:0,
    tournamentCommssion: 0,
    furious4GamesTotal: 0,
    furious4GamesAmount: 0,
    totalProfitLoss: 0, // This refers to total Profit loss but Excluding Rs. 500 to be sent to Users. It's TODO
  });

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);
  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = getItemWithExpiry("user");
        if (!user.token) {
          toast.error("You have no access");
          return;
        }
        const startDate = dateRange[0];
        const endDate = dateRange[1];
        const filter = {
          startDate,
          endDate,
        };
        setLoading(true);
        const { data } = await axios.get(
          `${config.REST_ENDPOINT}/stats/overview?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setLoading(false);
        setStats((prevStats) => {
          const totalComission = new Big(data?.totalComission);
          const totalReferralsBonus = new Big(data?.totalReferralsBonus);
          const totalSignupUsers = new Big(data?.totalWalletBonusCount);

          const totalProfitLoss = Number(
            totalComission
              .add(data.tournamentCommssion)
              .minus(totalSignupUsers.mul(50).plus(totalReferralsBonus))
              .toFixed(2)
          );
          return {
            ...prevStats,
            // totalUsers: data.totalUsers,
            totalWalletBonuses: data.totalWalletBonuses,
            totalReferrals: data.totalReferrals,
            totalReferralsBonus:
              Math.round(data.totalReferralsBonus * 100) / 100,
            // depositCounts: data.depositCount,
            // depositAmount: data.depositAmount,
            // withdrawCounts: data.withdrawlCount,
            // withdrawlTotalAmount: data.withdrawlTotalAmount,
            totalWalletBonusCount: data.totalWalletBonusCount,
            totalRoomJoinFees: Math.round(data.totalRoomJoinFees * 100) / 100,
            totalWinAmount: Math.round(data.totalWinAmount * 100) / 100,
            totalComission: Math.round(data.totalComission * 100) / 100,
            quickGamesTotal: data.quickGamesTotal,
            quickGamesAmount: Math.round(data.quickGamesAmount * 2 * 100) / 100,
            classicGamesTotal: data.classicGamesTotal,
            tournamentGamesTotal: data.tournamentGamesTotal,
            tournamentAmount:data.tournamentAmount,
            tournamentCommssion: data.tournamentCommssion,
            furious4GamesTotal: data.furious4GamesTotal,
            furious4GamesAmount: data.furious4GamesAmount,
            classicGamesAmount:
              Math.round(data.classicGamesAmount * 2 * 100) / 100,
            totalWalletBonusess: data.totalWalletBonuses,
            totalProfitLoss,
          };
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dateRange]);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <div>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <SystemSecurityUpdateGoodIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Signup Users</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.totalWalletBonusCount}
                </Typography>
                {/* <form>
                  <label>Enter Tournament Name</label>
                  <input type="text" />
                </form> */}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CurrencyBitcoinIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Signup Bonus</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.totalWalletBonuses}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <AccountCircleRounded className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Referral Bonus</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.totalReferralsBonus}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <People className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Users</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.totalUsers}
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <ArrowUpward className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Deposit Counts</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {" "}
                  {stats.depositCounts}
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CurrencyExchange className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Deposits</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹ {stats.depositAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <AccountBalanceIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Withdrawal</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.withdrawCounts}
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <MonetizationOnIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Withdrawal Amount</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.withdrawlTotalAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <PaletteIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Quick GamePlay</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.quickGamesTotal}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Handshake className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Classic GamePlay</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.classicGamesTotal}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <PriceCheckIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4"> Total Furious4 Gameplay</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.furious4GamesTotal}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Handshake className={classes.icon} />
              <CardContent>
                <Typography variant="h4"> Total Quick Games Amount</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.quickGamesAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Handshake className={classes.icon} />
              <CardContent>
                <Typography variant="h4">
                  {" "}
                  Total Classic Games Amount
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.classicGamesAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <PriceCheckIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4"> Total Furious4 Amount</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {" "}
                  ₹{stats.furious4GamesAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Handshake className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Tournaments</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.tournamentGamesTotal}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Handshake className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Tournament Games Amount</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.tournamentAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Handshake className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Tournament Commssion</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.tournamentCommssion}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CurrencyRupeeIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Profit/Loss</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.totalProfitLoss}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <PriceCheckIcon className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Comission</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  ₹{stats.totalComission}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Home className={classes.icon} />
              <CardContent>
                <Typography variant="h4">Total Referrals</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  {stats.totalReferrals}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
