import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  FunctionField,
  usePermissions,
  DateTimeInput,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";
import axios from "axios";
import { toast } from "react-toastify";

const transactionFilters = [
  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
  <SelectArrayInput
    label="Filter by Status"
    source="status"
    choices={[
      { id: "success", name: "Success" },
      { id: "failed", name: "Failed" },
      { id: "pending", name: "Pending" },
      { id: "refund", name: "Refund" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Payment Gateway"
    source="paymentGateway"
    choices={[
      { id: "juspay", name: "JusPay" },
      { id: "cashfree", name: "CashFree" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by txnMode"
    source="txnMode"
    choices={[
      { id: "deposit", name: "Deposit" },
      { id: "withdrawalRequest", name: "Withdrawal" },
      { id: "withdrawalRefund", name: "Withdrawal Refund" },
      { id: "admin", name: "admin" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by txnType"
    source="txnType"
    choices={[
      { id: "credit", name: "Credit" },
      { id: "debit", name: "Withdrawal" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by paymentMode"
    source="paymentMode"
    choices={[
      { id: "UPI", name: "UPI" },
      { id: "CARD", name: "Card" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by payout Type"
    source="payoutType"
    choices={[
      { id: "IMPS", name: "Bank Transfer" },
      { id: "UPI", name: "UPI" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const Transactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search OrderID"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Txn Id"
        source="_id"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search TransferId"
        source="transferId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const handleGenerateInvoice = async (orderId: string) => {
    try {
      const response = await axios.post("/transactions/generate-invoice", {
        orderId,
      });

      if (response.status === 200) {
        const { invoiceUrl } = response.data;
        window.open(invoiceUrl, "_blank");
      } else {
        console.error("Failed to generate invoice")
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <FunctionField
            label="Txn Id"
            render={(record: any) => {
              if (
                ["withdrawalRequest", "withdrawalRefund"].includes(
                  record.txnMode
                )
              )
                return <TextField source="id" label="Txn Id" />;
            }}
          />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="amount" />
          <TextField source="mainwallet" />
          <TextField source="winwallet" />
          <TextField source="txnMode" />
          <TextField source="payoutType" />
          <TextField source="paymentGateway" />
          <TextField source="txnType" />
          <TextField source="transferId" label="Transfer Id" />
          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "pending") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "success") {
                return <ChipField source="status" color="success" />;
              } else {
                return <ChipField source="status" color="error" />;
              }
            }}
          />
          <FunctionField
            label="Invoice"
            render={(record: any) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleGenerateInvoice(record.orderId);
                }}
                download
              >
                Download Invoice
              </a>
            )}
          />
          <DateField source="createdAt" showTime label="Requested At" />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Transactions;
