import { useState } from "react";
import Box from "@mui/material/Box";
import PaymentsIcon from "@mui/icons-material/Payments";

import {
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";

import {
  HourglassTop,
  CheckBox,
  PersonAdd,
  CreditCard,
  People,
  Payments,
  Feedback,
  BugReport,
  Gamepad,
  Rule,
  AddCard,
  AdfScanner,
  DvrOutlined,
  DvrTwoTone,
  AttachMoney,
  LocalOffer,
  DashboardCustomizeRounded,
  PersonSearchRounded,
  SportsEsportsRounded,
  PaidRounded,
  Style,
  Dashboard,
} from "@mui/icons-material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SubMenu from "./SubMenu";

type MenuName =
  | "payoutMenu"
  | "kycMenu"
  | "gameMenu"
  | "gameRecords"
  | "withdrawalsMenu"
  | "revenueMenu"
  | "fraudMenu"
  | "couponMenu";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    payoutMenu: true,
    gameMenu: true,
    kycMenu: true,
    withdrawalsMenu: true,
    gameRecords: true,
    revenueMenu: true,
    fraudMenu: true,
    couponMenu: true,
  });

  const { permissions } = usePermissions();

  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const toggleGameMenu = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  // eslint-disable-next-line
  const toggleGameRecords = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  // eslint-disable-next-line
  const toggleRevenueMenu = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {(permissions.includes("admin") || permissions.includes("finance")) && (
        <>
          {/* <DashboardMenuItem primaryText="Revenue Report" /> */}

          <SubMenu
            handleToggle={() => handleToggle("revenueMenu")}
            isOpen={state.revenueMenu}
            name="Revenue Records"
            icon={<PostAddIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/dashboard"
              state={{ _scrollToTop: true }}
              primaryText={`Revenue Reports`}
              leftIcon={<AttachMoney />}
              dense={dense}
            />
            <MenuItemLink
              to="/ludo/joinfee/count"
              state={{ _scrollToTop: true }}
              primaryText={"Table Reports"}
              leftIcon={<AssessmentIcon />}
              dense={dense}
            />

            <MenuItemLink
              to="/tournaments/joinfee/count"
              state={{ _scrollToTop: true }}
              primaryText={"Tournament Reports"}
              leftIcon={<AssessmentIcon />}
              dense={dense}
            />
          </SubMenu>

          {permissions.includes("admin") && (
            <MenuItemLink
              to="/ludo/online/users"
              state={{ _scrollToTop: true }}
              primaryText={"User Count "}
              leftIcon={<Gamepad />}
              dense={dense}
            />
          )}
          <MenuItemLink
            to="/tax-deductions"
            state={{ _scrollToTop: true }}
            primaryText={`Tax Deductions`}
            leftIcon={<AttachMoney />}
            dense={dense}
          />
          <MenuItemLink
            to="/payment-dashboard"
            state={{ _scrollToTop: true }}
            primaryText="Payment Dashboard"
            leftIcon={<DashboardCustomizeRounded />}
            dense={dense}
          />
        </>
      )}
      {(permissions.includes("admin") || permissions.includes("support")) && (
        <>
          {/* --------KYC Menu is Hiding from the UI | Zoop Kyc Implemented ---------  */}
          {/* <SubMenu
            handleToggle={() => handleToggle("kycMenu")}
            isOpen={state.kycMenu}
            name="KYC Management"
            icon={<AdfScanner />}
            dense={dense}
          >
            <MenuItemLink
              to="/kyc-pending"
              state={{ _scrollToTop: true }}
              primaryText={`Pending Requests`}
              leftIcon={<PendingActions />}
              dense={dense}
            />
            <MenuItemLink
              to="/kyc-completed"
              state={{ _scrollToTop: true }}
              primaryText={`Completed Requests`}
              leftIcon={<Verified />}
              dense={dense}
            />
            <MenuItemLink
              to="/kyc-rejected"
              state={{ _scrollToTop: true }}
              primaryText={`Rejected Request`}
              leftIcon={<ThumbDownOffAlt />}
              dense={dense}
            />
          </SubMenu> */}
          {permissions.includes("admin") && (
            <>
              <MenuItemLink
                to="/users"
                state={{ _scrollToTop: true }}
                primaryText={`Users`}
                leftIcon={<People />}
                dense={dense}
              />
            </>
          )}
          <SubMenu
            handleToggle={() => handleToggle("gameRecords")}
            isOpen={state.gameRecords}
            name="Game Records"
            icon={<AdfScanner />}
            dense={dense}
          >
            <MenuItemLink
              to="/game-records"
              state={{ _scrollToTop: true }}
              primaryText={`Games Completed`}
              leftIcon={<DvrOutlined />}
              dense={dense}
            />

            <MenuItemLink
              to="/game-online"
              state={{ _scrollToTop: true }}
              primaryText={`Games Ongoing`}
              leftIcon={<DvrTwoTone />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/admin-transactions"
            state={{ _scrollToTop: true }}
            primaryText={`Admin Transactions`}
            leftIcon={<PersonAdd />}
            dense={dense}
          />
          
          <SubMenu
            handleToggle={() => toggleGameMenu("gameMenu")}
            isOpen={state.gameMenu}
            name="Deposits"
            icon={<Gamepad />}
            dense={dense}
          >
            <MenuItemLink
              to="/top-deposits"
              state={{ _scrollToTop: true }}
              primaryText="Top Deposits"
              leftIcon={<PersonAdd />}
              dense={dense}
              title="top-deposits"
            />
            <MenuItemLink
              to="/incomplete-deposit"
              state={{ _scrollToTop: true }}
              primaryText={`Incomplete Juspay Deposits`}
              leftIcon={<AddCard />}
              dense={dense}
            />
            <MenuItemLink
              to="/incomplete-cashfree-deposit"
              state={{ _scrollToTop: true }}
              primaryText={`Incomplete Cashfree Deposits`}
              leftIcon={<AddCard />}
              dense={dense}
            />
            <MenuItemLink
              to="/transactions"
              state={{ _scrollToTop: true }}
              primaryText={`Transactions`}
              leftIcon={<CreditCard />}
              dense={dense}
            />
            <MenuItemLink
              to="/game-transactions"
              state={{ _scrollToTop: true }}
              primaryText={`Game Transactions`}
              leftIcon={<Gamepad />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("withdrawalsMenu")}
            isOpen={state.withdrawalsMenu}
            name="Withdrawals"
            icon={<PaymentsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/top-withdrawals"
              state={{ _scrollToTop: true }}
              primaryText="Top Withdrawals"
              leftIcon={<PersonAdd />}
              dense={dense}
              title="top-withdrawals"
            />
            {permissions.includes("admin") && (
              <>
                <MenuItemLink
                  to="/withdrawals"
                  state={{ _scrollToTop: true }}
                  primaryText={`WithDrawals`}
                  leftIcon={<Payments />}
                  dense={dense}
                />
              </>
            )}

            <MenuItemLink
              to="/processing-requests"
              state={{ _scrollToTop: true }}
              primaryText="In Process"
              leftIcon={<HourglassTop />}
              dense={dense}
            />
            <MenuItemLink
              to="/processed-requests"
              state={{ _scrollToTop: true }}
              primaryText="Processed Requests"
              leftIcon={<CheckBox />}
              dense={dense}
              title="Processed Requests"
            />
            <MenuItemLink
              to="/incomplete-withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Cashfree Withdrawals"
              leftIcon={<Rule />}
              dense={dense}
              title="Incomplete Withdrawal"
            />
            <MenuItemLink
              to="/payout/incomplete-juspay-withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Juspay Withdrawal"
              leftIcon={<Rule />}
              dense={dense}
              title="Incomplete Juspay Withdrawal"
            />
          </SubMenu>
          <MenuItemLink
            to="/kyc-zoop"
            state={{ _scrollToTop: true }}
            primaryText={`Zoop Kyc`}
            leftIcon={<People />}
            dense={dense}
          />
          {permissions.includes("admin") && (
            <>
              <MenuItemLink
                to="/all-tournaments"
                state={{ _scrollToTop: true }}
                primaryText={`Tournaments`}
                leftIcon={<AddCard />}
                dense={dense}
              />
            </>
          )}
        </>
      )}

      {permissions.includes("admin") && (
        <>
          <SubMenu
            handleToggle={() => handleToggle("fraudMenu")}
            isOpen={state.fraudMenu}
            name="Fraud"
            icon={<PersonSearchRounded />}
            dense={dense}
          >
            <MenuItemLink
              to="/fraud-games"
              state={{ _scrollToTop: true }}
              primaryText={`Games`}
              leftIcon={<SportsEsportsRounded />}
              dense={dense}
            />
            <MenuItemLink
              to="/fraud-transactions"
              state={{ _scrollToTop: true }}
              primaryText={`Transactions`}
              leftIcon={<PaidRounded />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("couponMenu")}
            isOpen={state.couponMenu}
            name="Coupons"
            icon={<Style />}
            dense={dense}
          >
            <MenuItemLink
              to="/coupon"
              state={{ _scrollToTop: true }}
              primaryText={`Create`}
              leftIcon={<LocalOffer />}
              dense={dense}
            />
            <MenuItemLink
              to="/users/admin/coupon-dashboard"
              state={{ _scrollToTop: true }}
              primaryText={`Dashboard`}
              leftIcon={<Dashboard />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/bug-reports"
            state={{ _scrollToTop: true }}
            primaryText={`Bug Reports`}
            leftIcon={<BugReport />}
            dense={dense}
          />
          <MenuItemLink
            to="/feedbacks"
            state={{ _scrollToTop: true }}
            primaryText={`Feedbacks`}
            leftIcon={<Feedback />}
            dense={dense}
          />
          <MenuItemLink
            to="/ludo/game-action/logs"
            state={{ _scrollToTop: true }}
            primaryText={"Game Action "}
            leftIcon={<Gamepad />}
            dense={dense}
          />
          <MenuItemLink
            to="/referrals"
            state={{ _scrollToTop: true }}
            primaryText={`Referrals`}
            leftIcon={<PersonAdd />}
            dense={dense}
          />
        </>
      )}
    </Box>
  );
};

export default Menu;
