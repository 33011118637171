import {
  Admin,
  Resource,
  fetchUtils,
  CustomRoutes,
} from "react-admin";
import { Route } from "react-router";

import dataprovider from "./providers/dataProvider";
import authProvider from "./providers/AuthProvider";
import { QueryClient } from "react-query";

import { Layout } from "./layout";
import { getItemWithExpiry } from "./utils";
import config from "./config";

import "./App.css";

import Dashboard from "./modules/Dashboard";
import Users from "./modules/users/Users";
import Transactions from "./modules/Transactions";
import AdminTransaction from "./modules/AdminTransactions";
import Withdrawals from "./modules/Withdrawals";
import BugReports from "./modules/BugReports";
import Feedbacks from "./modules/Feedbacks";
import GameConfig from "./modules/GameConfig";
import Tournaments from "./modules/tournaments/Tournaments";
import LoginPage from "./modules/LoginPage";
import ReferralRecords from "./modules/ReferralRecords";
import GameAction from "./modules/GameAction";
import GameRecords from "./modules/GameRecords";
import Configuration from "./modules/Configuaration";
import ProcessedRequests from "./modules/ProcessedRequests";
import ProcessingRequests from "./modules/ProcessingRequests";
import IncompleteWithdrawal from "./modules/IncompleteWithdrawal";
import IncompleteJuspayWithdrawal from "./modules/IncompleteJuspayWithdrawal";
import IncompleteDeposits from "./modules/IncompleteJuspayDeposits";
import Deposits from "./modules/Deposits";
import PaymentDashboard from "./modules/PaymentDashboard";
import KYCPending from "./modules/KYCPending";
import Details from "./modules/users/Details";
import KYCCompleted from "./modules/KYCCompleted";
import KYCRejected from "./modules/KYCRejected";
import GameTransaction from "./modules/GameTransaction";
import TournamentTransaction from "./modules/TournamentTransaction";
import KycZoop from "./modules/KycZoop";
import GameOnline from "./modules/GameOngoing";
import TaxDeductions from "./modules/TaxDeductions";
import CreateTournament from "./modules/tournaments/CreateTournament";
import EditTournament from "./modules/tournaments/EditTournament";
import ViewTournament from "./modules/tournaments/ViewTournament";
import UserCount from "./modules/UserCount";
import CreateCoupon from "./modules/coupons/CreateCoupon";
import FraudTransactions from "./modules/FraudTransactions";
import FraudGames from "./modules/FraudGames";
import TableReports from "./modules/TableReports";
import TournamentReport from "./modules/TournamentReport";
import CouponDetails from "./modules/coupons/CouponDetails";
import CouponHistory from "./modules/coupons/Coupons";
import AdminTransactions from "./modules/AdminTransactions";
import IncompleteCashfreeDeposits from "./modules/IncompleteCashfreeDeposits";
import TopDeposits from "./modules/TopDeposit";
import TopWithdrawals from "./modules/TopWithdrawal";

function App() {
  const attachHeaders = (url: string, options: fetchUtils.Options = {}) => {
    const customHeaders = (options.headers ||
      new Headers({
        Accept: "application/json",
      })) as Headers;
    // Get token from storage
    const { token } = getItemWithExpiry("user");
    // Attach token in header - which will be added on all api requests
    customHeaders.set("Authorization", `Bearer ${token}`);
    options.headers = customHeaders;
    return fetchUtils.fetchJson(url, options);
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        structuralSharing: false,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retryDelay: 10000,
      },
    },
  });

  return (
    <Admin
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataprovider(config.REST_ENDPOINT, attachHeaders)}
      requireAuth
      layout={Layout}
      queryClient={queryClient}
    >
      {(permissions) => (
        <>
          <CustomRoutes>
            <Route path="/configuration" element={<Configuration />} />
          </CustomRoutes>

          {(permissions.includes("admin") ||
            permissions.includes("finance")) && (
            <>
              <Resource name="dashboard" list={Dashboard} />
              <Resource name="payment-dashboard" list={PaymentDashboard} />
              <Resource name="tax-deductions" list={TaxDeductions} />
              <Resource name="ludo/joinfee/count" list={TableReports} />
              <Resource
                name="tournaments/joinfee/count"
                list={TournamentReport}
              />
            </>
          )}
          {(permissions.includes("admin") ||
            permissions.includes("support")) && (
            <>
              <Resource name="game-records" list={GameRecords} />

              {/* --------KYC Menu is Hiding from the UI | Zoop Kyc Implemented ---------              */}
              <Resource name="kyc-pending" list={KYCPending} />
              <Resource name="kyc-completed" list={KYCCompleted} />
              <Resource name="kyc-rejected" list={KYCRejected} />
              <Resource name="deposits" list={Deposits} />
              <Resource name="game-transactions" list={GameTransaction} />
              <Resource name="kyc-zoop" list={KycZoop} />
              <Resource name="processed-requests" list={ProcessedRequests} />
              <Resource name="processing-requests" list={ProcessingRequests} />
              <Resource
                name="incomplete-withdrawal"
                list={IncompleteWithdrawal}
              />
              <Resource
                name="payout/incomplete-juspay-withdrawal"
                list={IncompleteJuspayWithdrawal}
              />
              <Resource name="incomplete-deposit" list={IncompleteDeposits} />
              <Resource
                name="incomplete-cashfree-deposit"
                list={IncompleteCashfreeDeposits}
              />
              <Resource name="ludo/game-action/logs" list={GameAction} />
              <Resource name="ludo/online/users" list={UserCount} />
              <Resource name="game-online" list={GameOnline} />
              <Resource name="wallets/transactions" list={AdminTransaction} />
            </>
          )}

          <Resource name="transactions" list={Transactions} />
          <CustomRoutes>
            <Route path="/fraud-transactions" element={<FraudTransactions />} />
            <Route path="/fraud-games" element={<FraudGames />} />
            <Route path="/admin-transactions" element={<AdminTransactions />} />
          </CustomRoutes>
          {/* <Resource name="tournaments" list={Tournaments}/> */}
          {permissions.includes("admin") && (
            <>
              <Resource name="bug-reports" list={BugReports} />
              <Resource name="withdrawals" list={Withdrawals} />
              <Resource name="top-withdrawals" list={TopWithdrawals} />
              <Resource name="top-deposits" list={TopDeposits} />
              <Resource name="game-transactions" list={GameTransaction} />
              <Resource
                name="tournaments/transaction/history"
                list={TournamentTransaction}
              />
              <Resource name="kyc-zoop" list={KycZoop} />
              <Resource name="feedbacks" list={Feedbacks} />
              <Resource
                name="all-tournaments"
                list={Tournaments}
                create={CreateTournament}
                edit={EditTournament}
                show={ViewTournament}
              />
              <Resource name="referrals" list={ReferralRecords} />
              <Resource name="game-config" list={GameConfig} />
              <Resource name="users" list={Users} show={Details} />
              <Resource name="coupon" list={CreateCoupon} />
              <Resource
                name="users/admin/coupon-dashboard"
                list={CouponHistory}
                show={CouponDetails}
              />
              <CustomRoutes>
                <Route
                  path="/users/admin/coupon-dashboard/:promoCode"
                  element={<CouponDetails />}
                />
              </CustomRoutes>
            </>
          )}
        </>
      )}
    </Admin>
  );
}

export default App;
