import{ useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  InputLabel,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { toast } from "react-toastify";
import { callRestApi } from "../../utils/callRestApi";
import { Link } from "react-router-dom";
const CouponPage = () => {
  const [description, setDescription] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [expireAt, setExpireAt] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");
  const [bonusType, setBonusType] = useState("percentage");
  const [wallet, setBonusWallet] = useState("");
  const [coupons, setCoupons] = useState<any>([]);
  const [promoCode, setPromoCode] = useState("");
  const handleAddCoupon = async () => {
    const localExpireDate = new Date(expireAt);
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    if (!dateRegex.test(expireAt)) {
      toast.error("Please fill out all the required fields!");
      return;
    }
    const utcExpireDate = new Date(
      Date.UTC(
        localExpireDate.getUTCFullYear(),
        localExpireDate.getUTCMonth(),
        localExpireDate.getUTCDate(),
        localExpireDate.getUTCHours(),
        localExpireDate.getUTCMinutes()
      )
    );
    const coupon = {
      description,
      minAmount,
      maxAmount,
      expireAt: utcExpireDate.toISOString(),
      bonusAmount,
      bonusType,
      wallet: wallet,
      promoCode,
    };

    if (
      !description ||
      !minAmount ||
      !maxAmount ||
      !bonusAmount ||
      !expireAt ||
      !promoCode ||
      !bonusType ||
      !wallet
    ) {
      toast.error("Please fill out all the required fields!");
      return;
    }

    try {
      const apiRes = await callRestApi("/users/coupon", "POST", coupon);
      if (apiRes?.data?.status) {
        toast(
          apiRes?.data?.message
            ? apiRes?.data?.message
            : "Coupon Updated. Please Refresh page to verify",
          {
            type: "success",
          }
        );
      } else {
        toast("Coupon created successfully!", {
          type: "success",
        });
        setCoupons([...coupons, coupon]);
      }
    } 
    catch (error: any) {
    }
    setPromoCode("");
    setDescription("");
    setMinAmount("");
    setMaxAmount("");
    setExpireAt("");
    setBonusAmount("");
    setBonusType("percentage");
    setBonusWallet("");
  };
  <Button
    variant="contained"
    style={{ marginLeft: "10px", marginTop: "10px" }}
    component={Link}
    to="users/admin/coupon-dashboard"
  >
    Coupon History
  </Button>;

  return (
    <div>
      <h2>Add New Coupon</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Minimum Amount"
            value={minAmount}
            onChange={(e) => setMinAmount(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Maximum Amount"
            value={maxAmount}
            onChange={(e) => setMaxAmount(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Cashback Amount"
            value={bonusAmount}
            onChange={(e) => setBonusAmount(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Cashback Type</FormLabel>
            <RadioGroup
              row
              aria-label="bonus-type"
              name="bonus-type"
              value={bonusType}
              onChange={(e) => setBonusType(e.target.value)}
            >
              <FormControlLabel
                value="percentage"
                control={<Radio />}
                label="Percentage"
              />
              <FormControlLabel
                value="rupees"
                control={<Radio />}
                label="Rupees"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Cashback Credit Wallet</FormLabel>
            <RadioGroup
              row
              aria-label="bonus-credit-wallet"
              name="bonus-credit-wallet"
              value={wallet}
              onChange={(e) => setBonusWallet(e.target.value)}
            >
              <FormControlLabel
                value="winningWallet"
                control={<Radio />}
                label="Winning Wallet"
                labelPlacement="end"
              />
              <FormControlLabel
                value="mainWallet"
                control={<Radio />}
                label="Main Wallet"
                labelPlacement="end"
              />
              <FormControlLabel
                value="bonusWallet"
                control={<Radio />}
                label="Bonus Wallet"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="expireAt" required>
            Coupon Expire Date
          </InputLabel>
          <TextField
            id="expireAt"
            name="expireAt"
            type="datetime-local"
            value={expireAt}
            onChange={(e) => setExpireAt(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        style={{ marginTop: "10px" }}
        onClick={handleAddCoupon}
      >
        Create
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={() => {
          setDescription("");
          setPromoCode("");
          setMinAmount("");
          setMaxAmount("");
          setExpireAt("");
          setBonusAmount("");
          setBonusType("percentage");
          setBonusWallet("");
        }}
      >
        Reset
      </Button>
      {coupons.length > 0 && (
        <>
          <h2 style={{ marginTop: "20px" }}>Coupons Created</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Description</strong>
                </TableCell>
                <TableCell>
                  <strong>Promo Code</strong>
                </TableCell>
                <TableCell>
                  <strong>Minimum Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Maximum Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Cashback Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Cashback Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Cashback Credit Wallet</strong>
                </TableCell>
                <TableCell>
                  <strong>Coupon Expire Date</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((coupon: any) => (
                <TableRow key={coupon.id}>
                  <TableCell>{coupon.description}</TableCell>
                  <TableCell>{coupon.promoCode}</TableCell>
                  <TableCell>{coupon.minAmount}</TableCell>
                  <TableCell>{coupon.maxAmount}</TableCell>
                  <TableCell>{coupon.bonusAmount}</TableCell>
                  <TableCell>{coupon.bonusType}</TableCell>
                  <TableCell>{coupon.wallet}</TableCell>
                  <TableCell>{coupon.expireAt.toString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

export default CouponPage;
