import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";

const BugReports = () => {
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search UserId"
        source="userId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search TableId"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <List
        resource="bug-reports"
        sort={{ field: "Created At", order: "DESC" }}
        filters={<UserNameFilter />}
      >
        <DatagridConfigurable bulkActionButtons={false}>
        <UserNameHyperlink label="User Name" source="username" to="userId" />
          <TextField source="userId" label="User ID"/>
          <TextField source="traceFile" label="Trace File"/>
          <TextField source="description" label="Description" />
          <DateField source="createdAt" label="Created At"/>
          <ChipField source="tableId" label="Table Id" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default BugReports;
