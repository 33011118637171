import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  FunctionField,
  ChipField,
  usePermissions,
} from "react-admin";
import { Button, ButtonGroup, Stack } from "@mui/material";
import { useState } from "react";
import KycImageModal from "../components/KycImageModal";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";

const KYCRejected = (props: object) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState({});
  const { permissions } = usePermissions();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
      ]}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="kyc.aadharNumber" />
          <TextField source="kyc.panNumber" />
          <ChipField source="kycStatus" color="error" />
          <FunctionField
            label="Documents"
            render={(record: any) => {
              return (
                <ButtonGroup
                  size="small"
                  variant="contained"
                  aria-label="small outlined button group"
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setImageModalOpen(true);
                      setImageModalData(() => {
                        return { ...record, imageToDisplay: "aadharCard" };
                      });
                    }}
                  >
                    Aadhar Card
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => {
                      setImageModalOpen(true);
                      setImageModalData(() => {
                        return { ...record, imageToDisplay: "panCard" };
                      });
                    }}
                  >
                    Pan Card
                  </Button>
                </ButtonGroup>
              );
            }}
          />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
      {imageModalOpen && (
        <KycImageModal
          open={imageModalOpen}
          close={() => setImageModalOpen(false)}
          data={imageModalData}
        />
      )}
    </>
  );
};

export default KYCRejected;
