import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const AdminTransactions = () => {
  const [loading, setLoading] = useState(true);
  const [adminData, setAdminData] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [orderIdFilter, setOrderIdFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [rowCount, setRowCount] = useState<number>();

  const columns = [
    { field: "orderId", headerName: "Order ID/Table ID", flex: 1 },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      renderCell: (params: any) => (
        <Link to={`/users/${params.row.userId}/show`}>
          {params.row.userName}
        </Link>
      ),
    },
    {
      field: "amount",
      headerName: "Refund Amount",
      flex: 1,
      sortComparator: (v1: any, v2: any) => parseFloat(v1) - parseFloat(v2),
    },
    {
      field: "createdAt",
      headerName: "Requested At",
      flex: 1,
      valueGetter: (params: any) =>
        new Date(params.row.createdAt).toLocaleString("en-GB"),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      valueGetter: (params: any) =>
        new Date(params.row.updatedAt).toLocaleString("en-GB"),
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    fetchAdminData();
    // eslint-disable-next-line
  }, [paginationModel, usernameFilter, orderIdFilter, amountFilter]);

  const fetchAdminData = useCallback(async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user?.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }
      const { page, pageSize } = paginationModel;
      let filter: any = { txnMode: "admin" };

      if (usernameFilter) {
        filter = { ...filter, userName: usernameFilter };
      }
      if (orderIdFilter) {
        filter = { ...filter, orderId: orderIdFilter };
      }
      if (amountFilter) {
        filter = { ...filter, amount: amountFilter };
      }

      let query = "";
      query += `&skip=${pageSize * page}&count=${pageSize}`;

      const response = await axios.get(
        `${config.REST_ENDPOINT}/transactions?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}${query}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      let filteredData = response.data.items;
      let rowCount = response.data.meta.totalCount;
      setRowCount(rowCount);
      setAdminData(filteredData);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching Admin transactions data:", error);
      setLoading(false);
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
  }, [paginationModel, usernameFilter, orderIdFilter, amountFilter]);

  const getRowId = (row: any) => row.orderId;

  return (
    <div>
      <div>
        <div
          style={{
            display: "inline-block",
            marginRight: "10px",
            marginTop: "5px",
          }}
        ></div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search by Username"
            variant="outlined"
            fullWidth
            value={usernameFilter}
            inputProps={{ minLength: 10 }}
            onChange={(e) => {
              setUsernameFilter(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search by Order ID"
            variant="outlined"
            fullWidth
            value={orderIdFilter}
            onChange={(e) => {
              setOrderIdFilter(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search by Refund Amount"
            variant="outlined"
            fullWidth
            value={amountFilter}
            inputProps={{ minLength: 10 }}
            onChange={(e) => {
              setAmountFilter(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
        <DataGrid
          pagination
          columns={columns}
          rows={adminData}
          rowCount={rowCount}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
          getRowId={getRowId}
          paginationMode="server"
        />
      </Paper>
      <ReactQueryDevtools />
    </div>
  );
};

export default AdminTransactions;
