import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  ChipField,
  FunctionField,
  useNotify,
  useRefresh,
  Confirm,
  usePermissions,
} from "react-admin";
import { Button, ButtonGroup, Stack } from "@mui/material";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";
import { useState } from "react";

import KycImageModal from "../components/KycImageModal";
import { CheckBoxRounded, Clear } from "@mui/icons-material";
import { callRestApi } from "../utils/callRestApi";

const KYCPendingDocuments = (props: object) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState({});
  const [actionRequiredRecord, setActionRequiredRecord] = useState<any>({});
  const [rejectConfirmModal, setRejectConfirmModal] = useState(false);
  const [approveConfirmModal, setApproveConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { permissions } = usePermissions();

  const notify = useNotify();
  const refresh = useRefresh();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
      ]}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const handleApprovalConfirm = async (record: any) => {
    const endPoint = `/kyc/accept/${record?._id}`;
    try {
      setLoading(true);
      const approveResponse = await callRestApi(endPoint, "GET");
      notify(
        approveResponse?.data?.status
          ? approveResponse?.data?.message
          : "KYC Approved",
        {
          multiLine: true,
          type: "success",
        }
      );
    } catch (error: any) {
      console.error(error?.response?.data?.message);
      notify(
        error?.response?.data?.message ||
          "Something Went Wrong Please Contact Adminstrator",
        { multiLine: true, type: "error" }
      );
    }
    setLoading(false);
    refresh();
    setApproveConfirmModal(false);
  };

  const handleRejectConfirm = async (record: any) => {
    const endPoint = `/kyc/reject/${record?._id}`;
    try {
      setLoading(true);
      const rejectResponse = await callRestApi(endPoint, "GET");
      notify(
        rejectResponse?.data?.status
          ? rejectResponse?.data?.message
          : "KYC Rejected",
        {
          multiLine: true,
          type: "success",
        }
      );
    } catch (error: any) {
      console.error(error?.response?.data?.message);
      notify(
        error?.response?.data?.message ||
          "Something Went Wrong Please Contact Adminstrator",
        { multiLine: true, type: "error" }
      );
    }
    setLoading(false);
    refresh();
    setRejectConfirmModal(false);
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="kyc.aadharNumber" />
          <TextField source="kyc.panNumber" />
          <ChipField source="kycStatus" color="info" />

          <FunctionField
            label="Documents"
            render={(record: any) => {
              return (
                <ButtonGroup
                  size="small"
                  variant="contained"
                  aria-label="small outlined button group"
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setImageModalOpen(true);
                      setImageModalData(() => {
                        return { ...record, imageToDisplay: "aadharCard" };
                      });
                    }}
                  >
                    Aadhar Card
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => {
                      setImageModalOpen(true);
                      setImageModalData(() => {
                        return { ...record, imageToDisplay: "panCard" };
                      });
                    }}
                  >
                    Pan Card
                  </Button>
                </ButtonGroup>
              );
            }}
          />

          <DateField source="updatedAt" showTime />
          <FunctionField
            label="Action"
            render={(record: any) => {
              return (
                <ButtonGroup
                  size="small"
                  variant="contained"
                  aria-label="small outlined button group"
                >
                  <Button
                    color="success"
                    size="small"
                    onClick={() => {
                      setApproveConfirmModal(true);
                      setActionRequiredRecord(() => {
                        return record;
                      });
                    }}
                    startIcon={<CheckBoxRounded />}
                  >
                    Approve
                  </Button>
                  <Button
                    color="error"
                    size="small"
                    onClick={() => {
                      setRejectConfirmModal(true);
                      setActionRequiredRecord(() => {
                        return record;
                      });
                    }}
                    startIcon={<Clear />}
                  >
                    Reject
                  </Button>
                </ButtonGroup>
              );
            }}
          />
        </DatagridConfigurable>
      </List>
      {imageModalOpen && (
        <KycImageModal
          open={imageModalOpen}
          close={() => setImageModalOpen(false)}
          data={imageModalData}
        />
      )}
      {approveConfirmModal && actionRequiredRecord && (
        <Confirm
          isOpen={approveConfirmModal}
          title={`Approve KYC for - ${actionRequiredRecord?.userName}`}
          content="Are you sure you want to approve this request?"
          onConfirm={() => handleApprovalConfirm(actionRequiredRecord)}
          onClose={() => setApproveConfirmModal(false)}
          loading={loading}
        />
      )}

      {rejectConfirmModal && actionRequiredRecord && (
        <Confirm
          isOpen={rejectConfirmModal}
          title={`Reject KYC for - ${actionRequiredRecord?.userName}`}
          content="Are you sure you want to reject this request?"
          onConfirm={() => handleRejectConfirm(actionRequiredRecord)}
          onClose={() => setRejectConfirmModal(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default KYCPendingDocuments;
