import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  ShowButton,
  FunctionField,
  useRefresh,
  usePermissions,
  DatagridConfigurable,
  SelectArrayInput,
  FilterForm,
  FilterButton,
  Button,
  useTranslate,
} from "react-admin";

import { toast } from "react-toastify";
import config from "../../config";
import { SetStateAction, useState } from "react";
import axios from "axios";
import { getItemWithExpiry } from "../../utils/sessionData";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const QuickFilter = ({ label }: any) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Wallet"
    source="wallet"
    choices={[
      { id: "mainWallet", name: "Main Wallet" },
      { id: "winningWallet", name: "Winning Wallet" },
      { id: "bonusWallet", name: "Bonus Wallet" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Status"
    source="expireAt"
    choices={[
      { id: "active", name: "Active" },
      { id: "expired", name: "Expired" },
    ]}
  />,
  <QuickFilter
    source="isDeleted"
    label="Deleted Coupons"
    defaultValue={true}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const CouponDashboard = (props: object) => {
  const refresh = useRefresh();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [promoCodeToDelete, setPromoCodeToDelete] = useState(null);
  const { permissions } = usePermissions();

  const handleDelete = async (promoCode: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const response = await axios.delete(
        `${config.REST_ENDPOINT}/users/coupon/${promoCode}?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Coupon deleted successfully!");
        refresh();
      } else {
        toast.error("Failed to delete the coupon");
      }
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  const openDeleteConfirmation = (promoCode: SetStateAction<null>) => {
    setPromoCodeToDelete(promoCode);
    setDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Promo Code"
        source="promoCode"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Min Amount"
        source="minAmount"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Max Amount"
        source="maxAmount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      handleDelete(promoCodeToDelete || "");
    }
    closeDeleteConfirmation();
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        {permissions.includes("admin") && (
          <DatagridConfigurable bulkActionButtons={false}>
            <TextField source="promoCode" label="Promo Code" />
            <TextField source="description" label="Description" />
            <TextField source="minAmount" label="Min Amount" />
            <TextField source="maxAmount" label="Max Amount" />
            <FunctionField
              label="Cashback "
              render={(record: { bonusAmount: any; bonusType: any }) => {
                const { bonusAmount, bonusType } = record;
                if (bonusType === "percentage") {
                  return `${bonusAmount}%`;
                } else {
                  return bonusAmount;
                }
              }}
            />
            <TextField source="wallet" label="Cashback Credit Wallet" />
            <DateField source="expireAt" showTime label="Coupon Expire Date" />
            <DateField source="createdAt" showTime label="Created At" />
            <DateField source="updatedAt" showTime label="Updated At" />
            <FunctionField
              label="Status"
              render={(record: any) => {
                const currentDate = new Date();
                const expireDate = new Date(record.expireAt);
                const isExpired = expireDate < currentDate;

                const style: CSSProperties = {
                  backgroundColor: record.isDeleted
                    ? "gray"
                    : isExpired
                    ? "red"
                    : "green",
                  padding: "6px",
                  borderRadius: "4px",
                  color: "white",
                  textAlign: "center",
                };

                let statusText = record.isDeleted
                  ? "Deleted"
                  : isExpired
                  ? "Expired"
                  : "Active";

                return <div style={style}>{statusText}</div>;
              }}
            />
            <FunctionField
              textAlign="center"
              label="Actions"
              render={(record: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginRight: "20px",
                      flexDirection: "row",
                    }}
                  >
                    <FunctionField
                      label="Delete Coupon"
                      render={(record: any) => (
                        <>
                          <Button
                            sx={{ textTransform: "none" }}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            label="Delete"
                            disabled={record.isDeleted}
                            onClick={() =>
                              openDeleteConfirmation(record.promoCode)
                            }
                          />
                        </>
                      )}
                    />
                    <ShowButton
                      resource={`/users/admin/coupon-dashboard/${record.promoCode}`}
                    />
                  </div>
                );
              }}
            />
          </DatagridConfigurable>
        )}
      </List>
      <Dialog open={deleteConfirmation} onClose={closeDeleteConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteConfirmation(false)}
            color="primary"
            label="Cancel"
          ></Button>
          <Button
            onClick={() => handleDeleteConfirmation(true)}
            color="primary"
            label="Yes"
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CouponDashboard;
