import { AuthProvider} from "react-admin";
import axios, { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import { getItemWithExpiry } from "../utils";
import config from "../config";

type JWTDecoded = {
  sub: string;
  roles: string[];
};

type AuthResponse = {
  user_id: string;
  accessToken: string;
};

const authProvider: AuthProvider = {
  login: ({ mobileNo, otp }) => {
    return axios
      .post(`auth/verify?key=${config.MAINTENANCE_BYPASS_KEY}`, {
        mobileNo,
        otp,
      })
      .then((response: AxiosResponse<AuthResponse | any>) => {
        if (response.status === 201) {
          const result: JWTDecoded = jwt_decode(response.data?.accessToken);
          if (!result) throw new Error("Access token invalid");

          const { roles, sub } = result;
          if (roles[0] !== "player" || roles.length !== 1) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                userId: sub,
                mobileNo,
                roles,
                time: new Date(),
                token: response.data?.accessToken,
              })
            );
            return response.data;
          } else throw new Error("You don't have administrator access!");
        }
      });
  },
  logout: () => {
    localStorage.removeItem("user");
    return Promise.resolve();
  },
  checkAuth: () =>
    getItemWithExpiry("user") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("user");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: "Ludo Admin",
    }),
  getPermissions: () => {
    const user = getItemWithExpiry("user");
    return user ? Promise.resolve(user.roles) : Promise.reject();
  },
};

export default authProvider;
