import {
  TopToolbar,
  ExportButton,
  List,
  Button,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  useListContext,
  usePermissions,
} from "react-admin";
import { Stack } from "@mui/material";
import { ReactQueryDevtools } from "react-query/devtools";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import { useState } from "react";

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Status"
    source="status"
    choices={[
      { id: "success", name: "Success" },
      { id: "failed", name: "Failed" },
      { id: "pending", name: "Pending" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Payment Gateway"
    source="paymentGateway"
    choices={[
      { id: "juspay", name: "JusPay" },
      { id: "cashfree", name: "CashFree" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const IncompleteDeposits = (props: any) => {
  const [orderId, setOrderId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { permissions } = usePermissions();

  const onRefund = async () => {
    const user = getItemWithExpiry("user");

    if (!user.token) {
      toast.error("You have no right to refund transaction");
      return;
    }

    axios
      .patch(
        config.REST_ENDPOINT +
          "/transactions/refund" +
          `?key=${config.MAINTENANCE_BYPASS_KEY}`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => toast.success("Refund Successful!"))
      .catch((err: any) => toast.error(err.response.data.message));
  };

  const ListActions = () => {
    const { data } = useListContext();
    if (data) {
      setDisabled(data[0].status === "success");
    }
    setOrderId(data ? data[0].orderId : "");

    return (
      <TopToolbar>
        <FilterButton filters={transactionFilters} />
        <SelectColumnsButton />
        {permissions.includes("admin") && <ExportButton />}
      </TopToolbar>
    );
  };

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Transfer Id"
        source="orderId"
        resettable
        alwaysOn
      />
    </Filter>
  );
  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <TextField source="username" label="User Name" />
          <TextField source="amount" label="Txn Amount (RS)" />
          <TextField source="winningWallet" label="Win Wallet (RS)" />
          <TextField source="mainWallet" label="Main Wallet (RS)" />
          <DateField source="date" showTime label="Date" />
          <ChipField source="txnType" label="Type" />
          <TextField source="paymentGateway" />
          <ChipField source="status" />
          <ChipField source="juspayStatus" />
          <Button
            onClick={onRefund}
            label="Refund"
            disabled={disabled}
            variant="contained"
            sx={{ textTransform: "none" }}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default IncompleteDeposits;
