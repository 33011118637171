import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  useListContext,
  BooleanField,
  usePermissions,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";
import { useState } from "react";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../config";

const ProcessingRequests = (props: object) => {
  const [orderId, setOrderId] = useState("");
  const [, setDisabled] = useState(false);
  const [, setCashfreeStatus] = useState("");
  const { permissions } = usePermissions();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Payout Type"
      source="payoutType"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "IMPS", name: "IMPS" },
      ]}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => {
    const { data } = useListContext();
    setOrderId(data ? data[0].orderId : "");
    setCashfreeStatus(data ? data[0].payoutStatus : "");

    return (
      <TopToolbar>
        <FilterButton filters={transactionFilters} />
        <SelectColumnsButton />
        {permissions.includes("admin") && <ExportButton />}
      </TopToolbar>
    );
  };

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search OrderId"
        source="orderId"
        resettable
        alwaysOn
      />
    </Filter>
  );
  // eslint-disable-next-line
  const onVerify = () => {
    const user = getItemWithExpiry("user");
    if (!user.token) {
      toast.error("You have no right to refund transaction");
      return;
    }

    setDisabled(true);
    axios
      .patch(
        config.REST_ENDPOINT +
          `/payout/cashfree/verify` +
          `?key=${config.MAINTENANCE_BYPASS_KEY}`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setCashfreeStatus(res.data.cashfreeStatus);
        setDisabled(false);
        toast.success(`Verified Successfully - ${res.data.cashfreeStatus}`);
      })
      .catch((err: any) => {
        setDisabled(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="orderId" label="Order ID" />
          <TextField source="id" label="Txn Id" />
          <TextField source="currency" />
          <TextField source="amount" />
          <TextField source="paymentGateway" />
          <TextField source="payoutType" />
          <BooleanField source="autoPay" />
          <ChipField source="payoutStatus" />
          <DateField source="createdAt" showTime label="Requested At" />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default ProcessingRequests;
