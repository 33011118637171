import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { get, isEmpty } from "lodash";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { CloseRounded, RemoveRedEye } from "@mui/icons-material";
import { Confirm } from "react-admin";

import PageLoader from "../../components/PageLoader";
import GridItem from "../../components/GridItem";
import UserInfoRow from "../../components/UserInfoRow";
import { callRestApi } from "../../utils/callRestApi";
import SimpleTable from "../../components/SimpleTable";

const ViewTournament = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tournamentInfo, setTournamentInfo] = useState<object>({});
  const [leaderBoardRoundNumber, setLeaderBoardRoundNumber] =
    useState<number>(1);
  const [isCancelTournamentConfirmOpen, setIsCancelTournamentConfirmOpen] =
    useState(false);
  const [cancelReason, setCancelReason] = useState<string>("");
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const [, setSelectdPlayer] = useState([]);
  const { id = "" } = useParams();

  useEffect(() => {
    fetchTournamentDetails(id);
    // eslint-disable-next-line
  }, []);

  const fetchTournamentDetails = async (id: string) => {
    setLoading(true);
    try {
      const { data } = (await callRestApi(
        `/tournaments/admin/${id}`,
        "GET",
        {}
      )) as AxiosResponse;
      setTournamentInfo(data);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const handleCancelTournament = async () => {
    try {
      if (isEmpty(cancelReason)) {
        toast("Tournament Cancellation Reason is Mandatory", {
          type: "error",
        });
        return;
      }
      setConfirmModalLoading(true);
      const { status } = (await callRestApi(
        `/tournaments/${id}/forceTerminate`,
        "POST",
        { tournamentId: id, reason: cancelReason }
      )) as AxiosResponse;
      if (status === 200) {
        toast("Tournament Cancelled Successfully", {
          type: "success",
        });
      }
      setCancelReason("");
      setIsCancelTournamentConfirmOpen(false);
      setConfirmModalLoading(false);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setConfirmModalLoading(false);
  };

  const winninPrizesColumns = [
    { field: "minRank", headerName: "Min Rank", flex: 1 },
    { field: "maxRank", headerName: "Max Rank", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "percentage", headerName: "Percentage", flex: 1 },
  ];

  const leaderBoardColumns = [
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      renderCell: (params: GridValueGetterParams) =>
        !isEmpty(params?.row?.userName) ? (
          <Link to={`/users/${params.row.userId}/show`}>
            {params.row.userName}
          </Link>
        ) : (
          <>{params.row.userName}</>
        ),
    },
    { field: "rank", headerName: "Rank", flex: 1 },
    { field: "score", headerName: "Score", flex: 1 },
    { field: "winAmount", headerName: "Win Amount", flex: 1 },
  ];
  // eslint-disable-next-line
  const playersColumnns = [
    {
      field: "userId",
      headerName: "User ID",
      flex: 0.75,
      renderCell: (params: GridValueGetterParams) => (
        <Link to={`/users/${params.row.userId}/show`}>{params.row.userId}</Link>
      ),
    },
    {
      field: "lastPlayedRoundNo",
      headerName: "Last Round",
      flex: 0.75,
      align: "center",
    },
    {
      field: "lostRoundNo",
      headerName: "Lost Round",
      flex: 0.75,
      align: "center",
    },
    {
      field: "lastRoundScore",
      headerName: "Last Round Score",
      flex: 1,
      align: "center",
    },
    {
      field: "rank",
      headerName: "Rank",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: "Round Info",
      flex: 0.75,
      renderCell: (params: GridValueGetterParams) => (
        <RemoveRedEye
          titleAccess="Show Round Info"
          onClick={() => setSelectdPlayer(params.row)}
          sx={{
            "&:hover": { color: "green" },
            cursor: "pointer",
          }}
        />
      ),
      headerAlign: "center",
      align: "center",
    },
  ];
  // eslint-disable-next-line
  const playerRoundInfoColumns = [
    { field: "roundNo", headerName: "Round Number", flex: 1 },
    { field: "tableId", headerName: "Table Id", flex: 1 },
    { field: "score", headerName: "Score", flex: 1 },
  ];

  const gamePlatTableColumns = [
    { field: "tableId", headerName: "Table Id", flex: 1 },
    { field: "roundNo", headerName: "Round Number", flex: 1 },
    { field: "winner", headerName: "Winner", flex: 1 },
    {
      headerName: "Players",
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        // eslint-disable-next-line
        {
          return params.row.players.forEach((player: any) => {
            return (
              <Link
                to={`/users/${player.userId}/show`}
                style={{ marginRight: "10px" }}
              >
                {player.playerId}
              </Link>
            );
          });
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Started At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    {
      field: "updatedAt",
      headerName: "Finished At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.updatedAt).toLocaleString()}`,
    },
  ];
  if (loading) {
    return <PageLoader />;
  }
  const tournamentStatus = get(tournamentInfo, "status", "");
  return (
    <Grid container spacing={1}>
      <Grid item xs={3.5}>
        <GridItem>
          <Box>
            <h4 style={{ textAlign: "center" }}>
              {get(tournamentInfo, "name")}
            </h4>
            <hr />
            <UserInfoRow
              label="Entry Fee"
              value={"₹" + get(tournamentInfo, "joinFee", "-")}
            />
            <UserInfoRow
              label="Registration Till"
              value={dayjs(get(tournamentInfo, "registerTill")).format(
                "MMMM D, YYYY h:mm A"
              )}
            />
            <UserInfoRow
              label="Starts At"
              value={dayjs(get(tournamentInfo, "startAt")).format(
                "MMMM D, YYYY h:mm A"
              )}
            />
            <UserInfoRow
              label="Ends At"
              value={dayjs(get(tournamentInfo, "endAt")).format(
                "MMMM D, YYYY h:mm A"
              )}
            />
            <UserInfoRow
              label="Current Round"
              value={get(tournamentInfo, "currentRoundNo", "-")}
            />
            <UserInfoRow
              label="Total Rounds"
              value={get(tournamentInfo, "totalRounds", "-")}
            />
            <UserInfoRow
              label="Players Per Game"
              value={get(tournamentInfo, "noPlayersPerGame", "-")}
            />

            <UserInfoRow
              label="Joined / Max Player"
              value={`${get(tournamentInfo, "noJoinedPlayers", "-")} / ${get(
                tournamentInfo,
                "maxNoPlayers",
                "-"
              )}`}
            />

            {!["ended", "canceled"].includes(tournamentStatus) && (
              <Button
                fullWidth
                style={{
                  marginTop: "10px",
                }}
                color="error"
                variant="contained"
                onClick={() => setIsCancelTournamentConfirmOpen(true)}
              >
                <CloseRounded />
                Cancel Tournament
              </Button>
            )}
          </Box>
        </GridItem>
        <GridItem style={{ marginTop: "10px" }}>
          <Box>
            <h3>Prize Pool</h3>
            <SimpleTable
              columns={winninPrizesColumns}
              data={get(tournamentInfo, "winningPrizes", [])}
            />
          </Box>
        </GridItem>
      </Grid>
      <Grid item xs={8.5}>
        <GridItem>
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Leaderboard for round - {leaderBoardRoundNumber}</h3>
              <div>
                <FormControl sx={{ m: 1, width: 200 }}>
                  <InputLabel id="round-number">Round Number</InputLabel>
                  <Select
                    id="round-number"
                    onChange={(e) =>
                      setLeaderBoardRoundNumber(Number(e.target.value))
                    }
                    input={<OutlinedInput label="Name" />}
                    sx={{ lineHeight: 2.5 }}
                  >
                    {[...Array(get(tournamentInfo, "totalRounds", 2) - 1 + 1)]
                      .map((_, i) => i + 1)
                      .map((num, index) => (
                        <MenuItem key={index} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <SimpleTable
              columns={leaderBoardColumns}
              data={[]}
              endPointData={{
                apiEndpoint: `/tournaments/${id}/leaderboard/${leaderBoardRoundNumber}`,
              }}
              dataMode="server"
              filterDisabled
              sortDisabled
              rowId="userId"
            />
          </Box>
          <Box>
            <h4>Gameplay Table Details</h4>
            <SimpleTable
              columns={gamePlatTableColumns}
              endPointData={{
                apiEndpoint: "/game-online",
              }}
              filter={{
                tournamentId: id,
              }}
              dataMode="server"
              rowId="tableId"
            />
          </Box>
        </GridItem>
      </Grid>

      {isCancelTournamentConfirmOpen && (
        <Confirm
          isOpen={isCancelTournamentConfirmOpen}
          title="Are you sure want to cancel the tournament?"
          content={
            <>
              <h6>Please Mention Reason for Cancellation of Tournament</h6>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Reason"
                onChange={(e: any) => {
                  setCancelReason(e.target.value);
                }}
              />
            </>
          }
          onConfirm={() => {
            handleCancelTournament();
            setIsCancelTournamentConfirmOpen(false);
          }}
          onClose={() => setIsCancelTournamentConfirmOpen(false)}
          loading={confirmModalLoading}
        />
      )}
    </Grid>
  );
};

export default ViewTournament;
