import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import {
  get,
  isEqual,
  isUndefined,
  omitBy,
  sumBy,
  toNumber,
  toString,
} from "lodash";
import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { callRestApi } from "../../utils/callRestApi";
import { TournamentDataType, WinningPrizesType } from "./tournamentTypes";

const AddTournaments = () => {
  const [winningPriceRef, setWinningPriceRef] = useState<WinningPrizesType[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
    getValues,
    setValue,
  } = useForm<TournamentDataType>({
    defaultValues: {
      noPlayersPerGame: 2,
      maxNoPlayers: 2,
      minNoPlayers: 2,
      winningPrizes: [
        {
          minRank: 1,
          maxRank: 1,
          amount: "1",
          percentage: 0,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "winningPrizes",
    control,
  });

  const watchingWinningPrice = useWatch({ control, name: "winningPrizes" });
  const watchingJoinFee = useWatch({ control, name: "joinFee" });
  const watchMaxNoPlayers = useWatch({ control, name: "maxNoPlayers" });

  // This will set the updated value to state if there is any changes in winningprize inputs
  useEffect(() => {
    if (!isEqual(winningPriceRef, watchingWinningPrice)) {
      setWinningPriceRef(watchingWinningPrice);
    }
    // eslint-disable-next-line
  }, [watchingWinningPrice]);

  // This will update and set the calculated values to readonly form fields
  useEffect(() => {
    updateWinningPriceFields();
    // eslint-disable-next-line
  }, [winningPriceRef, watchingJoinFee, watchMaxNoPlayers]);

  const updateWinningPriceFields = () => {
    console.log(winningPriceRef);
    const formValues = getValues() as TournamentDataType;
    const { winningPrizes } = formValues;

    // Update Number of Winners
    setValue(
      "noOfWinners",
      sumBy(
        winningPrizes,
        (data: WinningPrizesType) => data.maxRank - data.minRank + 1
      )
    );

    // Update Total Percentage
    setValue(
      "totalPercentage",
      Number.isNaN(
        sumBy(
          winningPrizes,
          (data: WinningPrizesType) => data?.totalWinnerPercentage || 0
        )
      )
        ? 0
        : sumBy(
            winningPrizes,
            (data: WinningPrizesType) => data?.totalWinnerPercentage || 0
          )
    );

    // Update Total Overall Amount
    setValue(
      "totalOverAllAmount",
      sumBy(
        winningPrizes,
        (data: WinningPrizesType) => data.totalWinnerAmount || 0
      )
    );

    winningPrizes.forEach((winningPrice: WinningPrizesType, index: number) => {
      // Update Percentage
      setValue(
        `winningPrizes.${index}.percentage`,
        Number.isNaN(
          (parseFloat(winningPrice.amount) /
            (parseFloat(formValues.joinFee) * formValues.maxNoPlayers)) *
            100
        )
          ? 0
          : (parseFloat(winningPrice.amount) /
              (parseFloat(formValues.joinFee) * formValues.maxNoPlayers)) *
              100
      );
      // Update Total Winner Percentage
      setValue(
        `winningPrizes.${index}.totalWinnerPercentage`,
        Number.isNaN(
          (winningPrice.maxRank - winningPrice.minRank + 1) *
            winningPrice.percentage
        )
          ? 0
          : (winningPrice.maxRank - winningPrice.minRank + 1) *
              winningPrice.percentage
      );
      // Update Total Winner Amount
      setValue(
        `winningPrizes.${index}.totalWinnerAmount`,
        (winningPrice.maxRank - winningPrice.minRank + 1) *
          parseFloat(winningPrice.amount)
      );
      // Update total winner number
      setValue(
        `winningPrizes.${index}.totalWinnersNumber`,
        winningPrice.maxRank - winningPrice.minRank + 1
      );
    });
  };

  const onSubmit = async (data: TournamentDataType) => {
    data.startAt = dayjs(data.startAt).toISOString();
    data.endAt = dayjs(data.endAt).toISOString();
    data.registerTill = dayjs(data.registerTill).toISOString();
    data.maxNoPlayers = JSON.parse(toString(data.maxNoPlayers));
    data.minNoPlayers = JSON.parse(toString(data.minNoPlayers));
    data.isRepeatable = JSON.parse(toString(data.isRepeatable));
    data.isAutomatic = JSON.parse(toString(data.isAutomatic));
    data.featured = JSON.parse(toString(data.featured));
    data.custom = JSON.parse(toString(data.custom));
    data.winningPrizes.forEach((winningPrizeData: WinningPrizesType) => {
      winningPrizeData.minRank = toNumber(winningPrizeData.minRank);
      winningPrizeData.maxRank = toNumber(winningPrizeData.maxRank);
    });
    const payload = omitBy(data, (value) => value === "" || isUndefined(value));
    try {
      setLoading(true);
      const tournamentResponse = await callRestApi(
        "/tournaments",
        "POST",
        payload
      );
      toast(
        get(tournamentResponse, "data.tournamentId")
          ? "Tournament Created Successfully with id " +
              get(tournamentResponse, "data.tournamentId")
          : "Something Went Wrong",
        {
          type: get(tournamentResponse, "data.tournamentId")
            ? "success"
            : "error",
        }
      );
      reset();
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card style={{ padding: "25px" }}>
      <h4>Create Tournament</h4>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="name">Tournament Name</InputLabel>
              <TextField
                {...register("name", { required: "Name is required" })}
                id="name"
                name="name"
                placeholder="Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="noPlayersPerGame">
                Number of Players Per Game
              </InputLabel>
              <TextField
                {...register("noPlayersPerGame", {
                  required: "Number of Players per Game is required",
                })}
                id="noPlayersPerGame"
                name="noPlayersPerGame"
                placeholder="Number of Players"
                select
                fullWidth
                error={!!errors.noPlayersPerGame}
                helperText={
                  errors.noPlayersPerGame && errors.noPlayersPerGame.message
                }
                type="number"
                defaultValue={2}
              >
                <MenuItem value={""}>Select Option</MenuItem>
                <MenuItem value={2}>Two Player</MenuItem>
                <MenuItem value={3}>Three Player</MenuItem>
                <MenuItem value={4}>Four Player</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="joinFee">Tournament Join Fee</InputLabel>
              <TextField
                {...register("joinFee", {
                  required: "Join Fee is required",
                  pattern: {
                    message: "Join Amount Should be Only Number or Decimal",
                    value: /^[0-9]\d{0,9}(\.\d{1,3})?%?$/gm,
                  },
                })}
                id="joinFee"
                name="joinFee"
                placeholder="Join Fee"
                fullWidth
                error={!!errors.joinFee}
                helperText={errors.joinFee && errors.joinFee.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="startAt">Tournament Start Time</InputLabel>
              <TextField
                {...register("startAt", {
                  required: "Tournament Start Time is required",
                  validate: (value) =>
                    dayjs().format("YYYY-MM-DDTHH:mm") <
                      dayjs(value).format("YYYY-MM-DDTHH:mm") ||
                    "Tournament Start Time cannot be before current time",
                })}
                id="startAt"
                name="startAt"
                type="datetime-local"
                fullWidth
                error={!!errors.startAt}
                helperText={errors.startAt && errors.startAt.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="minNoPlayers">
                Minimum Number of Players for Tournament
              </InputLabel>
              <TextField
                {...register("minNoPlayers", {
                  required: "Minimum Number of Players is required",
                })}
                id="minNoPlayers"
                name="minNoPlayers"
                placeholder="Number of Players"
                type="number"
                fullWidth
                error={!!errors.minNoPlayers}
                helperText={errors.minNoPlayers && errors.minNoPlayers.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxNoPlayers">
                Maximum Number of Players for Tournament
              </InputLabel>
              <TextField
                {...register("maxNoPlayers", {
                  required: "Maximum Number of Players is required",
                })}
                id="maxNoPlayers"
                name="maxNoPlayers"
                placeholder="Maximum Number of Players"
                type="number"
                fullWidth
                error={!!errors.maxNoPlayers}
                helperText={errors.maxNoPlayers && errors.maxNoPlayers.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="isRepeatable">
                is This Repeatable Tournament?
              </InputLabel>
              <FormControl error={!!errors.isRepeatable} component="fieldset">
                <Controller
                  rules={{ required: "Please select the option" }}
                  control={control}
                  name="isRepeatable"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.isRepeatable && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#f44336",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.isRepeatable.message}
                  </span>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="isAutomatic">
                is This Automatic Tournament?
              </InputLabel>
              <FormControl error={!!errors.isRepeatable} component="fieldset">
                <Controller
                  rules={{ required: "Please select the option" }}
                  control={control}
                  name="isAutomatic"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.isAutomatic && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#f44336",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.isAutomatic.message}
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="featured">
                is this Featured Tournament?
              </InputLabel>
              <FormControl error={!!errors.featured} component="fieldset">
                <Controller
                  rules={{ required: "Please select the option" }}
                  control={control}
                  name="featured"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.featured && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#f44336",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.featured.message}
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("noOfWinners")}
                id="noOfWinners"
                name="noOfWinners"
                type="text"
                fullWidth
                label="No Of Winners"
                InputProps={{ readOnly: true }}
                error={!!errors.noOfWinners}
                helperText={errors.noOfWinners && errors.noOfWinners.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("totalPercentage")}
                id="totalPercentage"
                name="totalPercentage"
                type="text"
                fullWidth
                InputProps={{ readOnly: true }}
                label="Total Percentage"
                error={!!errors.totalPercentage}
                helperText={
                  errors.totalPercentage && errors.totalPercentage.message
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("totalOverAllAmount")}
                id="totalOverAllAmount"
                name="totalOverAllAmount"
                type="text"
                label="Total OverAll Amount"
                fullWidth
                InputProps={{ readOnly: true }}
                error={!!errors.totalOverAllAmount}
                helperText={
                  errors.totalOverAllAmount && errors.totalOverAllAmount.message
                }
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="alias">Alias Name</InputLabel>
              <TextField
                {...register("alias", {
                  required: {
                    value:
                      String(watch("isRepeatable")) === "true" ||
                      String(watch("isAutomatic")) === "true",
                    message:
                      "Alias is Required If Tournament is Repeatable or Automatic",
                  },
                })}
                id="alias"
                name="alias"
                disabled={
                  String(watch("isRepeatable")) !== "true" &&
                  String(watch("isAutomatic")) !== "true"
                }
                placeholder="Alias Name"
                fullWidth
                error={!!errors.alias}
                helperText={errors.alias && errors.alias.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="custom">Custom Sounds</InputLabel>
              <FormControl error={!!errors.custom} component="fieldset">
                <Controller
                  rules={{ required: "Please select the option" }}
                  control={control}
                  name="custom"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.custom && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#f44336",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.custom.message}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  append({
                    minRank: 1,
                    maxRank: 1,
                    amount: "1",
                    percentage: 0.1,
                  })
                }
              >
                Add Winning Detail
              </Button>
            </Box>
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            {fields.map((field, index) => {
              return (
                <Grid
                  item
                  key={field.id}
                  xs={12}
                  md={2}
                  style={{ marginBottom: "5px" }}
                >
                  <Card style={{ padding: "10px" }}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="space-between">
                        <h5>Winning Prize</h5>
                        {index > 0 && (
                          <HighlightOff
                            onClick={() => remove(index)}
                            color="error"
                          />
                        )}
                      </Box>

                      <TextField
                        label="Minimum Rank"
                        {...register(
                          `winningPrizes.${index}.minRank` as const,
                          {
                            required: "Minimum Rank is required field",
                            pattern: /^\d+$/,
                          }
                        )}
                        error={
                          get(errors, `winningPrizes.${index}.minRank`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.minRank.message`,
                          ""
                        )}
                        type="number"
                      />
                      <TextField
                        label="Maximum Rank"
                        {...register(
                          `winningPrizes.${index}.maxRank` as const,
                          {
                            required: "Maximum Rank is required field",
                            pattern: /^\d+$/,
                          }
                        )}
                        error={
                          get(errors, `winningPrizes.${index}.maxRank`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.maxRank.message`,
                          ""
                        )}
                        type="number"
                      />
                      <TextField
                        label="Amount"
                        {...register(`winningPrizes.${index}.amount`, {
                          required: "Amount is required field",
                        })}
                        error={
                          get(errors, `winningPrizes.${index}.amount`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.amount.message`,
                          ""
                        )}
                        type="text"
                      />
                      <TextField
                        label="Percentage"
                        {...register(`winningPrizes.${index}.percentage`, {
                          required: "Percentage is required field",
                        })}
                        error={
                          get(errors, `winningPrizes.${index}.percentage`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.percentage.message`,
                          ""
                        )}
                        type="text"
                        inputProps={{
                          step: "0.1",
                        }}
                      />

                      <TextField
                        label="Total Amount"
                        {...register(
                          `winningPrizes.${index}.totalWinnerAmount`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Total Percentage"
                        {...register(
                          `winningPrizes.${index}.totalWinnerPercentage`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Total Winners"
                        {...register(
                          `winningPrizes.${index}.totalWinnersNumber`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <br />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box display="flex" justifyContent="space-between">
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={() => reset()}
                style={{ maxWidth: "50%" }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                style={{ maxWidth: "50%" }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};
export default AddTournaments;
