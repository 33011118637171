import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  DateTimeInput,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/react-admin/UserNameHyperlink";

const GameRecords = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
    <SelectArrayInput
      label="Filter by GameType"
      source="gameType"
      choices={[
        { id: "quick", name: "Quick" },
        { id: "classic", name: "Classic" },
        { id: "furious4", name: "Furious 4" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by Outcome"
      source="outcome"
      choices={[
        { id: "lost", name: "Lost" },
        { id: "won", name: "Won" },
      ]}
    />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Join Fee"
        source="joinAmount"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search RoomCode"
        source="roomCode"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      actions={<ListActions />}
      filters={<UserNameFilter />}
    >
      <ListToolbar />
      <DatagridConfigurable bulkActionButtons={false}>
        <UserNameHyperlink source="userName" to="userId" label="User Name" />
        <TextField source="gameType" label="Room Type" />
        <TextField source="roomCode" label="Room Code" />
        <TextField source="roomSize" label=" No. of players" />
        <TextField source="winners" label="No of Winners" />
        <TextField source="joinAmount" label="Join Amount" />
        <TextField source="winOrLoseAmount" label="Win or Lose" />
        <TextField source="outcome" label="Outcome" />
        <TextField source="status" label="Status" />
        <DateField source="createdAt" showTime label="Game Started At" />
        <DateField
          source="completedAt"
          showTime
          label="Game Completed At"
          sortable={false}
        />
      </DatagridConfigurable>
    </List>
  );
};

export default GameRecords;
